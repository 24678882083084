<template>
	<teleport to="#toast-container">
		<div v-show="visible" class="toast show cookie-toast">
			<div class="toast-header cookie-toast-header">
				<cookie-bite-icon />
				<span>{{ $t('title') }}</span>
			</div>
			<div class="toast-body cookie-toast-body">
				<p>{{ $t('body') }}</p>
				<button @click="accept" class="btn btn-outline btn-sm">OK</button>
			</div>
		</div>
	</teleport>
</template>

<i18n>
	{
		"ru": {
			"title": "Cookies",
			"body": "Этот сайт использует cookies для хранения данных. Продолжая использовать сайт, вы даёте согласие на работу с этими файлами."
		},
		"en": {
			"title": "Cookies",
			"body": "This site uses cookies for data storage. To continue to use this site you consent to the use of cookies."
		}
	}
</i18n>

<script>
import CookieBiteIcon from '@/components/icons/CookieBiteIcon.vue';

export default {
	data() {
		return {
			visible: !localStorage.getItem('cookiesAccepted'),
		};
	},
	methods: {
		accept() {
			localStorage.setItem('cookiesAccepted', true);
			this.visible = false;
		},
	},
	components: {
		CookieBiteIcon,
	},
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

.cookie-toast {
	background-color: $text-white;

	&-header {
		display: flex;
		align-items: center;
		gap: 0.5rem;
		border: 1px solid $primary-dark;
		border-bottom: unset;
		color: $primary-dark;
	}

	&-body {
		color: $text-secondary;
		border: 1px solid $primary-dark;

		button {
			width: 100%;
			color: $primary-dark;
			border-color: $primary-dark;

			&:hover {
				color: $text-white;
				background-color: $primary-dark;
			}

			&:active {
				background-color: $landing-button-active;
				border-color: $primary;
			}
		}
	}
}
</style>
