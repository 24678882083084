export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поддерживаемое оборудование"])},
        "subtitle-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автономные шлемы виртуальной реальности:"])},
        "subtitle-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["«VR-ready» компьютер и шлем виртуальной реальности:"])}
      },
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supported VR headsets"])},
        "subtitle-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standalone VR headsets"])},
        "subtitle-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PC VR headsets"])}
      }
    }
  })
}
