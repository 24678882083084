<template>
	<div class="landing">
		<navbar @showFeedback="handleShowFeedback" />
		<flask-block />
		<video-block />
		<about-block />
		<chemistry-block />
		<devices-block />
		<price @showFeedback="handleShowFeedback" />
		<sliders-block />
		<contact-block />
		<footbar @showFeedback="handleShowFeedback" />
		<feedback-modal v-model:show="showFeedback" v-model:request="feedbackRequest" @submit="handleSubmit" />
		<confirm-modal v-model:show="showConfirm" />
	</div>
</template>

<script>
import Navbar from '@/components/navbar/ru/Navbar.vue';
import FlaskBlock from '@/views/landing/ru/FlaskBlock.vue';
import VideoBlock from '@/views/landing/common/VideoBlock.vue';
import AboutBlock from './AboutBlock.vue';
import ChemistryBlock from './ChemistryBlock.vue';
import DevicesBlock from '@/views/landing/common/DevicesBlock.vue';
import Price from './Price.vue';
import SlidersBlock from './SlidersBlock.vue';
import ContactBlock from './ContactBlock.vue';
import Footbar from '@/components/footbar/ru/Footbar.vue';
import FeedbackModal from '@/components/modals/feedback-modal/ru/FeedbackModal.vue';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';

export default {
	data() {
		return {
			showFeedback: false,
			showConfirm: false,
			feedbackRequest: 'buy',
		};
	},
	methods: {
		handleSubmit() {
			this.showConfirm = true;
		},
		handleShowFeedback(request) {
			this.feedbackRequest = request;
			this.showFeedback = true;
		},
	},
	components: {
		Navbar,
		FlaskBlock,
		VideoBlock,
		AboutBlock,
		ChemistryBlock,
		DevicesBlock,
		Price,
		SlidersBlock,
		ContactBlock,
		Footbar,
		FeedbackModal,
		ConfirmModal,
	},
};
</script>

<style lang="scss" scoped>
.landing {
	padding: 0 1.25rem;
	overflow-x: hidden;

	&-navbar {
		max-width: 117rem;
		margin: 1.125rem auto 2rem auto;

		@include media-breakpoint-down(lg) {
			margin-bottom: 2rem;
		}

		@include media-breakpoint-down(sm) {
			margin-bottom: 3rem;
		}
	}

	.video-block,
	.about-block,
	.devices-block,
	.sliders-block,
	.contacts-block {
		margin-bottom: 9rem;

		@include media-breakpoint-down(lg) {
			margin-bottom: 8rem;
		}

		@include media-breakpoint-down(sm) {
			margin-bottom: 4rem;
		}
	}

	.flask-block {
		@include media-breakpoint-down(md) {
			margin-bottom: 8rem;
		}

		@include media-breakpoint-down(sm) {
			margin-bottom: 4rem;
		}
	}
}
</style>
