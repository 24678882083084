<template>
	<Popper :content="label" hover placement="top" arrow class="slider-card">
		<a :href="href" target="_blank" :class="{ 'ancher-disabled': href === '' }">
			<div class="slider-card-container" :style="background"></div>
		</a>
	</Popper>
</template>

<script>
export default {
	props: {
		href: {
			type: String,
			default: '',
			required: false,
		},
		src: {
			type: String,
			default: '',
			required: false,
		},
		label: {
			type: String,
			default: '',
			required: false,
		},
	},
	computed: {
		background() {
			return {
				background: `url(${this.src}) no-repeat center`,
				'background-size': 'contain',
				'background-origin': 'content-box',
			};
		},
	},
};
</script>

<style scoped lang="scss">
.slider-card {
	:deep(.popper) {
		@extend %roboto-body-1;

		background: $primary-dark;
		width: 25rem;
		padding: 1.5rem 1rem;
		border-radius: 0.375rem;
		text-align: center;
		color: $text-white;

		@include media-breakpoint-down(xl) {
			padding: 1rem 0.75rem;
			width: 16.75rem;
		}

		@include media-breakpoint-down(md) {
			padding: 0.5rem 0.25rem;
			width: 13.3rem;
		}
	}

	:deep(.popper #arrow::before) {
		background: $primary-dark;
	}

	:deep(.popper:hover),
	:deep(.popper:hover > #arrow::before) {
		background: $primary-dark;
	}

	.ancher-disabled {
		pointer-events: none;
		cursor: default;
	}

	&-container {
		width: 25rem;
		height: 17rem;
		padding: 2rem;
		border: 2px solid $primary-dark;

		@include media-breakpoint-down(xl) {
			padding: 1.375rem;
			width: 16.75rem;
			height: 11.375rem;
		}

		@include media-breakpoint-down(md) {
			padding: 1rem;
			width: 13.3rem;
			height: 9.06rem;
		}
	}
}
</style>
