<template>
	<div class="content page">
		<h1 class="header">{{ header }}</h1>
		<div v-for="(s, idx) in sections" :key="`section-${idx}`">
			<h2 class="section-header">{{ s.header }}</h2>
			<p v-for="(p, pidx) in s.paragraphs" :key="`paragraph=-${pidx}`" v-html="p" class="paragraph"></p>
		</div>
	</div>
</template>

<script>
import PRIVACY from '@/json/privacy.json';

export default {
	computed: {
		header() {
			return PRIVACY.header;
		},
		sections() {
			return PRIVACY.sections;
		},
	},
};
</script>

<style scoped lang="scss">
.content {
	display: flex;
	flex-direction: column;
	margin: 0 auto 2rem;
}

@include media-breakpoint-down(sm) {
	.header {
		font-size: 1.5rem;
		text-align: center;
	}

	.section-header {
		font-size: 1.25rem;
	}
}

.header,
.section-header {
	margin: 1rem 0;
	align-self: center;
}
</style>
