export default {
    methods: {
        async handleInput(ev) {
            const value = ev.target.value;
            this.localValue = null;
            await this.$nextTick();
            this.localValue = this.setter ? this.setter(value) : value;
        },
    },
    props: {
        inputId: {
            type: String,
            default: '',
        },
        value: {
            type: [String, Number],
            default: '',
        },
        setter: {
            type: Function,
            default: null,
        },
        placeholder: {
            type: String,
            default: '',
        },
        error: {
            type: Boolean,
            default: false,
        },
        errorMsg: {
            type: String,
            default: '',
        },
        required: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        localValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('update:value', value);
            },
        },
        textPlaceholder() {
            return this.required ? this.placeholder + '*' : this.placeholder;
        },
    },
};
