<template>
    <div class="slider" @swiped-left="slideToNext" @swiped-right="slideToPrev">
        <transition-group name="items__item" tag="div" class="slider__items">
            <slot v-for="(item, index) in displayedItems" :item="item" :index="index" :keyValue="item._key" />
        </transition-group>
        <button @click="slideToPrev" :disabled="btnDisabled" class="slider__prev-btn">
            <left-icon class="slider-icon" />
        </button>
        <button @click="slideToNext" :disabled="btnDisabled" class="slider__next-btn">
            <right-icon class="slider-icon" />
        </button>
        <div :class="[`slider__navigation`, { 'slider__navigation--show': navigation }]">
            <div
                @click="slideTo(item)"
                v-for="item in modBase"
                :key="`navitem-${item}`"
                :class="['slider__navigation__item', { 'slider__navigation__item--current': item - 1 === modCenterElIndex }]"
            ></div>
        </div>
    </div>
</template>

<script>
// Cyclic horizontal scroll component.

// *** How to use ***
// An array of N elements is passed to the MVSlider as items property (N may be any).
// key property is required in <custom-component />, item and index properties are optional:

// <mv-slider :items="items">
//   <template #default="{ keyValue, item, index }">
//     <custom-omponent :key="keyValue" :item="item" :index="index" />
//   </template>
// </mv-slider>

// *** How it works ***
// Five components are always displayed inside the MVSlider at the same time.
// Info about these components are selected from the passed items property and stored in the displayedItems variable.

// About displayedItems formation
// Further the elements of the displayedItems are replaced with the corresponding indexes of the items property.

// In beforeMount hook displayedItems formed as follows:
// [N-2, N-1, 0, 1, 2]

// Pressing previous or next buttons causes a change in the displayedItems according to the following rules:
// [N-2, N-1, 0, 1, 2] <- (slideToPrev)
// [N-3, N-2, N-1, 0, 1]

// [N-2, N-1, 0, 1, 2] -> (slideToNext)
// [N-1, 0, 1, 2, 3]

// Scrolling animation is implemented by using classes of transition group (v-move, v-leave-active, v-leave-to)
// A _key is added to each element in displayedItems according to the requirements of the transition group.

// This key must be unique and must not change over the life of the <custom-component />.
// Read the official vue documentation for more information about transition group

// *** Buttons customization ***
// If you need to change styles of control buttons,
// it is proposed to modify their classes: slider__prev-btn и slider__next-btn.

import { nanoid } from 'nanoid/non-secure';
import LeftIcon from '@/components/icons/ChevronLeftIcon';
import RightIcon from '@/components/icons/ChevronRightIcon';

export default {
    data() {
        return {
            displayedItems: [],
            centerElIndex: 0,
            btnDisabled: false,
            interval: null,
        };
    },
    props: {
        items: { type: Array, default: () => [] },
        navigation: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        LeftIcon,
        RightIcon,
    },
    beforeMount() {
        if (this.items.length === 0) {
            return;
        }
        let temp = [];
        if (this.items.length === 1) {
            for (let i = 0; i < 5; i++) temp.push(this.items[0]);
        } else if (this.items.length === 2) {
            for (let i = 0; i < 5; i++) {
                let index = i % 2;
                temp.push(this.items[index]);
            }
        } else {
            temp = [this.items[this.items.length - 2], this.items[this.items.length - 1], ...this.items.slice(0, 3)];
        }

        this.displayedItems = temp.map(item => ({
            ...item,
            _key: this.getKey(),
        }));
    },
    mounted() {
        this.interval = setInterval(this.slideToNext, 4000);
    },

    beforeUnmount() {
        clearInterval(this.interval);
    },

    methods: {
        getIndex(val) {
            const remainder = val % this.items.length;
            if (val >= 0) {
                return remainder;
            }
            return this.getIndex(remainder + this.items.length);
        },
        toggleBtn() {
            this.btnDisabled = !this.btnDisabled;
        },
        slideToPrev() {
            this.toggleBtn();
            this.centerElIndex -= 1;
            this.displayedItems.pop();
            let unshiftElIndex = this.getIndex(this.centerElIndex - 2);
            this.displayedItems.unshift({
                ...this.items[unshiftElIndex],
                _key: this.getKey(),
            });

            clearInterval(this.interval);
            this.interval = setInterval(this.slideToNext, 4000);
            setTimeout(() => {
                this.toggleBtn();
            }, 100);
        },
        slideToNext() {
            this.toggleBtn();
            this.centerElIndex += 1;
            this.displayedItems.shift();
            let pushElIndex = this.getIndex(this.centerElIndex + 2);
            this.displayedItems.push({
                ...this.items[pushElIndex],
                _key: this.getKey(),
            });

            clearInterval(this.interval);
            this.interval = setInterval(this.slideToNext, 5000);
            setTimeout(() => {
                this.toggleBtn();
            }, 100);
        },
        slideTo(num) {
            const displacement = num - this.modCenterElIndex - 1;
            for (let i = 0; i < Math.abs(displacement); i++) {
                displacement > 0 ? this.slideToNext() : this.slideToPrev();
            }
        },
        getKey() {
            return 'item-' + nanoid();
        },
    },
    computed: {
        modBase() {
            return this.items.length < this.displayedItems.length ? this.items.length : this.displayedItems.length;
        },
        modCenterElIndex() {
            const mod = Math.abs(this.centerElIndex) % this.modBase;
            if (this.centerElIndex >= 0 || mod === 0) return mod;
            return this.modBase - mod;
        },
    },
};
</script>

<style lang="scss" scoped>
.slider {
    display: flex;
    justify-content: center;
    position: relative;

    &__items {
        display: flex;
        gap: 0.625rem;

        & > * {
            opacity: 0.5;
        }

        & > :nth-child(3) {
            opacity: 1;
        }
    }

    .items__item-move {
        transition: transform 0.25s;
    }

    .items__item-leave-active {
        position: absolute;
    }

    .items__item-leave-to {
        opacity: 0;
    }

    .btn.disabled {
        opacity: 1;
    }

    &__prev-btn,
    &__next-btn {
        position: absolute;
        display: flex;
        align-items: center;
        height: 100%;
        min-width: 3.625rem;
        border: none;

        @include media-breakpoint-only(xs) {
            min-width: unset;
        }

        // info about customization of material design icons: https://www.npmjs.com/package/vue-material-design-icons
        .material-design-icon.slider-icon {
            width: 10rem;
            height: 10rem;
            color: $text-white;

            @include media-breakpoint-down(md) {
                width: 7rem;
                height: 7rem;
            }

            @include media-breakpoint-down(sm) {
                width: 4rem;
                height: 4rem;
            }

            @include media-breakpoint-only(xs) {
                width: 2rem;
                height: 2rem;
            }
        }

        .material-design-icon.slider-icon > .material-design-icon__svg {
            width: 10rem;
            height: 10rem;
            color: $text-white;

            @include media-breakpoint-down(md) {
                width: 7rem;
                height: 7rem;
            }

            @include media-breakpoint-down(sm) {
                width: 4rem;
                height: 4rem;
            }

            @include media-breakpoint-only(xs) {
                width: 2rem;
                height: 2rem;
            }
        }

        &,
        &:focus,
        &:active {
            background-color: rgba($color: $black, $alpha: 0);
            box-shadow: none;
        }

        &:hover {
            background-color: rgba($color: $black, $alpha: 0.3);
        }

        &:disabled {
            background-color: rgba($color: $black, $alpha: 0);
        }
    }

    &__prev-btn {
        left: 0;
        justify-content: flex-end;
        padding-right: 1.25rem;

        @include media-breakpoint-down(md) {
            padding-right: 0.25rem;
            padding-left: 0.25rem;
        }
    }

    &__next-btn {
        right: 0;
        justify-content: flex-start;
        padding-left: 1.25rem;

        @include media-breakpoint-down(md) {
            padding-right: 0.25rem;
            padding-left: 0.25rem;
        }
    }

    &__navigation {
        display: none;
        position: absolute;
        top: 90%;
        left: 50%;
        transform: translateX(-50%);

        @include media-breakpoint-down(sm) {
            pointer-events: none;
        }

        &__item {
            width: 0.75rem;
            height: 0.75rem;
            border-radius: 50%;
            background-color: $text-white;

            @include media-breakpoint-down(sm) {
                width: 0.375rem;
                height: 0.375rem;
            }

            &--current {
                background-color: $primary-dark;
            }
        }

        &__item + &__item {
            margin-left: 0.75rem;

            @include media-breakpoint-down(sm) {
                margin-left: 0.375rem;
            }
        }

        &--show {
            display: flex;
        }
    }
}
</style>
