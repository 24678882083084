<template>
	<div id="pirces-block" class="price-en">
		<h1 class="price-en-title">Prices</h1>
		<div class="price-en-text">
			<p>A typical price for Vic’s Science Studio software package is <span>240&nbsp;&#x20AC; per year per user.</span></p>
			<p>However, individual conditions may apply depending on your needs. </p>
			<p><a href="mailto:info@vics.studio" class="text-email" >Contact us</a>  for more information.</p>
		</div>
		<landing-button @click.stop="$emit('showFeedback')" outline>Get started</landing-button>
	</div>
</template>

<script>
import LandingButton from '@/components/LandingButton.vue';

export default {
	components: { LandingButton },
};
</script>

<style lang="scss" scoped>
.price-en {
	@extend %roboto-headline-4;
	text-align: center;
	color: $text-secondary;
	width: 100%;

	&-title {
		@extend %roboto-headline-3;
		color: $primary-dark;
		margin-bottom: 2.25rem;

        @include media-breakpoint-down(sm) {
            margin-bottom: 3rem;
        }
	}

    &-text {
        margin-bottom: 2.5rem;

        @include media-breakpoint-down(sm) {
            margin-bottom: 2rem;
        }

        & > p {
            @extend %roboto-headline-4;
            text-align: center;
            margin-bottom: 0;

            span {
                @extend %roboto-headline-4-bold;
            }
        }
    }
}
</style>
