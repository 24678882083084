<template>
    <div class="form-floating text-area">
        <textarea
            :value="localValue"
            @input="handleInput($event)"
            :id="inputId"
            :class="['form-control', { 'is-invalid': error }]"
            :placeholder="textPlaceholder"
            :disabled="disabled"
        ></textarea>
        <label :for="inputId">{{ textPlaceholder }}</label>
        <div class="invalid-tooltip">{{ errorMsg }}</div>
    </div>
</template>

<script>
import { inputMixin } from '@/mixins';

export default {
    emits: ['update:value'],
	mixins: [inputMixin],
};
</script>

<style lang="scss">
.text-area {
    position: relative;
}
</style>
