<template>
	<svg
		version="1.1"
		id="Layer_1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 600 600"
		style="enable-background: new 0 0 600 600"
		xml:space="preserve"
		class="vicicon-css"
	>
		<path
			d="M291.39,295.77c-12.14-17.83-30.56-31.03-51.06-37.01V220.4c7.78-1.48,14.61-7.25,17.14-14.8c0.74-2.17,1.21-4.45,1.24-6.74
	c0.06-2.13,0.02-3.87,0.03-5.82v-2.85v-1.43l-0.08-1.7c-0.19-2.27-0.63-4.56-1.52-6.68c-1.68-4.28-4.71-7.97-8.52-10.51
	c-3.78-2.54-8.52-3.86-12.92-3.78h-22.84h-22.84c-4.4-0.08-9.14,1.24-12.92,3.78c-3.81,2.55-6.83,6.24-8.52,10.51
	c-0.89,2.12-1.33,4.41-1.52,6.68l-0.08,1.7v1.43v2.85c0.01,1.95-0.03,3.69,0.03,5.82c0.03,2.29,0.5,4.57,1.24,6.74
	c2.53,7.55,9.36,13.31,17.14,14.8l0,38.37c-20.5,5.98-38.92,19.18-51.06,37.01c-12.74,18.67-18.09,42.13-15.23,64.58
	c2.57,22.6,14.21,43.89,31.32,58.81c17.02,15.08,39.64,23.66,62.44,23.76c22.8-0.1,45.42-8.68,62.44-23.76
	c17.1-14.92,28.75-36.21,31.32-58.81C309.48,337.9,304.13,314.44,291.39,295.77z M296.09,358.95c-1.41,9.95-4.54,19.66-9.34,28.49
	c-4.82,8.81-11.22,16.75-18.8,23.33c-15.13,13.24-35.03,20.64-55.09,20.65c-20.06,0-39.96-7.41-55.09-20.65
	c-7.58-6.58-13.98-14.51-18.8-23.33c-4.8-8.83-7.94-18.54-9.34-28.49c-2.88-19.92,1.53-40.95,12.66-57.91
	c10.92-17.08,28.22-30.06,47.95-35.74l2.83-0.81v-2.89l0-44.64l0-3.82l-3.76-0.02c-6.1-0.03-11.87-4.28-13.8-10.04
	c-0.48-1.43-0.81-2.92-0.82-4.44c-0.05-1.67-0.01-3.74-0.03-5.59v-2.85v-1.43l0.06-1.15c0.14-1.5,0.4-3.01,1-4.4
	c2.2-5.72,8.04-9.65,14.31-9.45h22.84h22.84c6.27-0.2,12.11,3.73,14.31,9.45c0.6,1.39,0.87,2.9,1,4.4l0.06,1.15v1.43v2.85
	c-0.01,1.85,0.03,3.92-0.03,5.59c0,1.52-0.34,3.01-0.82,4.44c-1.94,5.77-7.7,10.02-13.8,10.04l-3.76,0.02v3.82v44.5l0,0.14
	l-0.01,2.88l2.84,0.82c19.73,5.68,37.03,18.67,47.95,35.74C294.56,318,298.97,339.03,296.09,358.95z"
		/>
		<path
			d="M385.71,255.45c6.41,0.7,12.69,2.9,18.39,6.15c5.64,3.36,10.62,7.88,14.57,13.2c7.96,10.64,11.18,24.74,8.85,37.91
	c-2.3,13.17-10.13,25.34-21.24,32.62c-5.53,3.65-11.74,6.21-18.19,7.44c-6.46,1.12-13.12,1.05-19.38-0.48l1.33-7.56
	c10.84,1.12,21.74-1.8,30.15-8.1c8.41-6.29,14.27-15.66,16-25.92c1.86-10.24-0.45-21.04-6.21-29.82
	c-5.77-8.79-15.02-15.25-25.59-17.89L385.71,255.45z"
		/>
		<path
			d="M523.32,328.8l0.4-3.17l0.05-0.42c15.06-4.61,29.88-9.46,44.92-14.73l4.2-1.47l-0.96-3.9c-1.32-5.34-2.43-10.73-3.51-16.11
	c-1.07-5.39-2-10.8-3-16.2c-1.75-10.84-3.54-21.68-4.62-32.64l-0.39-4.01l-4.45,0.03l-47.83,0.28c-2.7-5.4-5.71-10.65-9.01-15.71
	l24.46-41.06l0,0l2.28-3.82l-3.25-2.37c-4.45-3.24-8.76-6.65-13.05-10.07c-4.28-3.44-8.48-6.98-12.71-10.48
	c-8.31-7.18-16.65-14.32-24.53-22.01l0,0l-2.89-2.81l-3.38,2.87l-36.38,30.93c-5.78-2.52-11.71-4.7-17.75-6.51l-8.66-46.89l0-0.01
	l-0.58-3.11l-3.06-0.16c-41.14-2.2-82.43-4.95-123.11-10.36c-10.16-1.43-20.1-3.19-30.19-5.55l-30.17-6.61
	c-5.09-1.08-9.92-2.24-15.22-3.26l-7.85-1.55l-7.94-0.96l-3.97-0.47l-4-0.17l-8-0.31c-5.33,0.28-10.69,0.14-15.98,0.92
	c-21.26,2.08-42.05,9-60.46,20.13c-9.16,5.66-17.55,12.55-25.09,20.31c-7.41,7.92-13.55,16.99-18.53,26.67
	c-4.78,9.76-8.04,20.31-9.54,31.12c-0.78,5.35-1.03,11-1,16.19l-0.04,15.5l-0.16,61.98l0.05,123.97l0.02,15.5
	c0.04,5.23-0.06,10.16,0.14,15.76c0.56,10.91,2.86,21.73,6.76,31.91c7.86,20.38,21.92,37.99,39.38,50.74
	c17.52,12.7,38.01,20.65,59.06,24.29c10.56,1.72,21.27,2.32,31.93,1.87c5.34-0.15,10.64-0.82,15.93-1.48
	c5.28-0.93,10.54-1.88,15.46-2.94c40.31-7.89,81.26-13.97,122.07-19.94l0.06-0.01l1.85-0.26l1.02-1.71l24.45-41.25l9.2,0.39
	c3.07-0.05,6.14-0.18,9.2-0.3l23.31,41.46l0.03,0.05l2.18,3.89l3.67-1.65c5.02-2.25,10.12-4.29,15.22-6.31l15.41-5.78
	c10.35-3.63,20.7-7.29,31.28-10.31l0.06-0.02l3.87-1.11l-0.81-4.36l-8.63-46.63c4.99-3.69,9.75-7.68,14.27-11.92l44.39,16.92
	l0.06,0.02l4.16,1.59l1.77-3.61c2.42-4.94,5.03-9.77,7.66-14.58l8.14-14.3c5.63-9.42,11.24-18.85,17.44-27.93l0.04-0.06l2.27-3.33
	l-3.41-2.83l-36.84-30.6c0.7-2.94,1.34-5.89,1.87-8.87C522.71,333.01,523.04,330.9,523.32,328.8z M534.33,404.84l-8.48,14.1
	c-2.16,3.45-4.35,6.88-6.6,10.28l-43.64-18.53l-3.42-1.45l-2.76,2.69l-0.05,0.04c-5.73,5.64-11.92,10.81-18.49,15.44l-3.14,2.21
	l0.82,3.64l0.01,0.06l10.31,46.03c-8.83,3.75-17.8,7.1-26.77,10.44l-15.54,5.41c-3.87,1.27-7.75,2.5-11.66,3.67l-21.65-42.13
	l-1.62-3.15l-3.89,0.14l-0.06,0c-3.98,0.13-7.97,0.35-11.95,0.41l-11.95-0.51l-3.97-0.17l-1.57,3.16l-0.03,0.05l-22.07,44.46
	c-19.88,2.53-39.71,5.2-59.55,8.03c-20.48,2.97-40.94,5.98-61.41,9.79c-5.19,1.06-10.11,1.89-15.01,2.72
	c-4.96,0.56-9.92,1.16-14.92,1.25c-9.98,0.33-19.98-0.33-29.81-2.01c-19.59-3.58-38.51-11.16-54.4-22.91
	c-7.92-5.9-14.9-12.87-21.04-20.54c-6-7.78-10.9-16.37-14.41-25.48c-3.46-9.13-5.47-18.76-5.95-28.49
	c-0.16-4.73-0.06-10.13-0.08-15.23l0.02-15.5l0.05-123.97l-0.16-61.98l-0.04-15.5c-0.06-5.13,0.14-9.83,0.82-14.72
	c1.3-9.66,4.16-19.12,8.42-27.93c4.45-8.7,9.91-16.9,16.6-24.13c6.83-7.07,14.45-13.43,22.86-18.68
	c16.9-10.35,36.23-16.86,56.07-19.02c4.94-0.79,9.95-0.67,14.93-1l7.49,0.22l3.74,0.12l3.72,0.41l7.45,0.83l7.36,1.39
	c4.85,0.87,10.09,2.07,15.11,3.09l30.44,6.37c10.06,2.25,20.49,4.01,30.81,5.35c40.21,4.96,80.4,7.3,120.68,9.12l5.2,48.01l0.4,3.66
	l3.77,1.06l0.01,0c7.83,2.15,15.47,4.96,22.84,8.38l3.48,1.62l2.75-2.52l0,0l35.02-32.1c7.65,5.78,15.04,11.85,22.41,17.96
	c4.15,3.59,8.34,7.15,12.44,10.8c3.04,2.72,6.04,5.48,9.01,8.28l-25.87,39.97l-2.01,3.11l2.16,3.18l0,0
	c4.47,6.51,8.41,13.38,11.77,20.52l1.63,3.46l3.72-0.16l47.59-2.05c2.14,9.34,3.84,18.75,5.55,28.16
	c0.86,5.42,1.79,10.84,2.57,16.27c0.58,4.05,1.11,8.1,1.57,12.17c-16.02,4.96-32.85,10.47-48.8,16.01l-0.73,4.25
	c0,0-2.99,18.03-3.99,21.85l-0.97,3.69l2.94,2.26l0.05,0.04l37.73,29.05C543.84,388.22,539.09,396.53,534.33,404.84z"
		/>
	</svg>
</template>

<script>
export default {
	name: 'VicIcon',
};
</script>

<style lang="scss" scoped>
.vicicon-css {
	width: 2.5rem;
	height: 2.5rem;
}
</style>
