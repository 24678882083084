export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "text-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваша заявка принята."])},
        "text-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы скоро свяжемся с вами."])},
        "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть"])}
      },
      "en": {
        "text-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your request has been accepted."])},
        "text-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will contact you soon."])},
        "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
      }
    }
  })
}
