<template>
	<div class="footbar-en">
		<div class="footbar-en-wrapper">
			<div class="footbar-en-content">
				<div class="footbar-en-content-links">
					<a :href="accountHref">Log in</a>
					<a href="#pirces-block">Prices</a>
					<a href="#contacts-block">Contacts</a>
				</div>
				<div class="footbar-en-content-logo">
					<vic-icon />
					<span>Vic’s Science Studio</span>
				</div>
				<div class="footbar-en-content-copyright">
					<span> &copy; {{ new Date().getFullYear() }} Vic’s Science Studio</span>
					<a :href="require('@/assets/pdf/privacy_policy.pdf')" target="_blank">Privacy</a>
					<router-link :to="{ name: 'UserAgreement' }">End User License Agreement</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import VicIcon from '@/components/icons/VicIcon.vue';
import { navbarMixin } from '@/mixins';

export default {
	mixins: [navbarMixin],
	components: {
		VicIcon,
	},
};
</script>

<style lang="scss" scoped>
.footbar-en {
	display: flex;
	justify-content: center;
	width: 100%;

	&-wrapper {
		display: flex;
		justify-content: center;
		width: 81.25rem;
		padding: 2.5rem 0;
		border-top: 1px solid $footbar-border;

		@include media-breakpoint-down(lg) {
			width: 100%;
		}
	}

	&-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 2.5rem;
		width: 35.375rem;

		@include media-breakpoint-down(sm) {
			gap: 1.5rem;
		}

		&-links {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;

			@include media-breakpoint-down(sm) {
				flex-direction: column;
				gap: 1.25rem;
			}

			a {
				@extend %roboto-link;
				color: $primary-dark;
				text-decoration: unset;

				&:hover {
					text-decoration: underline;
				}
			}
		}

		&-logo {
			@extend %jura-label;
			display: flex;
			align-items: center;
			gap: 0.5rem;
			color: $text-primary;
		}

		&-copyright {
			display: flex;
			align-items: center;
			gap: 1.5rem;

			@include media-breakpoint-down(sm) {
				flex-direction: column;
				gap: 1.25rem;
			}

			span {
				color: $text-secondary;
			}

			a {
				@extend %roboto-link;
				color: $primary-dark;
				text-decoration: unset;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}
</style>
