<template>
	<modal
		v-model:show="modalShow"
		:headerLine="false"
		:footerLine="false"
		size="small"
		align="top"
		class="feedback-modal"
	>
		<template v-slot:header>
			<h3 class="feedback-modal-title">Get started</h3>
		</template>
		<template v-slot:body>
			<form class="feedback-modal-form">
				<div class="feedback-modal-form-radio">
					<div v-for="option in options" :key="`option-${option.id}`" class="form-check form-check-inline">
						<input
							:id="option.id"
							:value="option.value"
							v-model="feedback.request"
							class="form-check-input"
							type="radio"
							:name="option.id"
						/>
						<label class="form-check-label" :for="option.id">{{ option.value }}</label>
					</div>
				</div>
				<text-input
					v-model:value="feedback.name"
					inputId="name"
					placeholder="Name"
					:error="v$.feedback.name.$error"
					:errorMsg="v$.feedback.name.$errors[0]?.$message"
					required
				/>
				<text-input
					v-model:value="feedback.surname"
					inputId="surname"
					placeholder="Surname"
					:error="v$.feedback.surname.$error"
					:errorMsg="v$.feedback.surname.$errors[0]?.$message"
					required
				/>
				<text-input
					v-model:value="feedback.email"
					inputId="email"
					placeholder="Email"
					:error="v$.feedback.email.$error"
					:errorMsg="v$.feedback.email.$errors[0]?.$message"
					required
				/>
				<text-input
					v-model:value="feedback.organisationSite"
					inputId="organisationSite"
					placeholder="Organisation website"
					:error="v$.feedback.organisationSite.$error"
					:errorMsg="v$.feedback.organisationSite.$errors[0]?.$message"
					required
				/>
				<text-input
					v-model:value="feedback.phone"
					inputId="phone"
					:setter="phoneSetter"
					:error="v$.feedback.phone.$error"
					:errorMsg="v$.feedback.phone.$errors[0]?.$message"
					placeholder="Phone"
				/>
				<text-input v-model:value="feedback.country" inputId="country" placeholder="Country" />
				<text-input v-model:value="feedback.occupation" inputId="occupation" placeholder="Occupation" />
				<text-area-input
					v-model:value="feedback.usage"
					inputId="usage"
					placeholder="How do you plan to use our solution?"
				/>
				<text-area-input
					v-model:value="feedback.devices"
					inputId="devices"
					placeholder="What kind of VR equipment do you already use, if any?"
				/>
			</form>
		</template>
		<template v-slot:footer>
			<div class="feedback-modal-footer-content">
				<landing-button @click.stop="confirm" outline size="medium">Submit</landing-button>
			</div>
		</template>
	</modal>
</template>

<script>
import Modal from '@/components/modals/Modal.vue';
import TextInput from '@/components/inputs/TextInput';
import TextAreaInput from '@/components/inputs/TextAreaInput';
import useVuelidate from '@vuelidate/core';
import LandingButton from '@/components/LandingButton';
import { required, maxLength, helpers } from '@vuelidate/validators';
import { request, gql } from 'graphql-request';

const document = gql`
	mutation createFeedback($data: FeedbackData!) {
		createFeedback(data: $data) {
			success
		}
	}
`;

export default {
	setup() {
		return { v$: useVuelidate() };
	},
	emits: ['update:show', 'submit'],
	data() {
		return {
			feedback: {
				request: 'Book a meeting',
				name: '',
				surname: '',
				email: '',
				organisationSite: '',
				phone: '',
				country: '',
				occupation: '',
				devices: '',
				usage: '',
			},
			options: [
				{ id: 'meeting', value: 'Book a meeting' },
				{ id: 'demo', value: 'Request a demo' },
			],
		};
	},
	methods: {
		async confirm() {
			this.v$.$touch();
			if (this.v$.$error) {
				return;
			}
			const data = { ...this.feedback };
			const variables = {
				data: data,
			};
			await request(process.env.VUE_APP_API_URL_EN, document, variables);
			this.$emit('submit', this.feedback);
			for (let key in this.feedback) {
				this.feedback[key] = '';
			}
			this.feedback.request = 'Book a meeting';
			this.v$.$reset();
			this.modalShow = false;
		},
		phoneSetter(val) {
			let value = val;
			const cleanValue = value.replace(/[^\d+]/g, '');
			if (this.$i18n.locale === 'en') {
				if (!cleanValue.startsWith('+') && cleanValue) value = '+' + cleanValue;
				else value = cleanValue;
			} else {
				if (cleanValue.match(/^[8+]/)) {
					value = cleanValue;
				} else {
					if (cleanValue.startsWith('7')) value = '+' + cleanValue;
					else value = cleanValue ? '+7' + cleanValue : '';
				}
			}

			return value;
		},
	},
	computed: {
		modalShow: {
			get() {
				return this.show;
			},
			set(show) {
				if (!show) this.v$.$reset();
				this.$emit('update:show', show);
			},
		},
	},
	validations() {
		return {
			feedback: {
				name: {
					required: helpers.withMessage('Field required', required),
				},
				surname: {
					required: helpers.withMessage('Field required', required),
				},
				email: {
					required: helpers.withMessage('Field required', required),
				},
				phone: {
					maxLength: helpers.withMessage('Max length is 16 digits', maxLength(16)),
				},
				organisationSite: {
					required: helpers.withMessage('Field required', required),
				},
			},
		};
	},
	components: {
		Modal,
		TextInput,
		TextAreaInput,
		LandingButton,
	},
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		event: {
			type: String,
			default: '',
		},
		confirmButtonStyle: {
			type: String,
			default: 'success', // 'success' or 'danger'
		},
	},
};
</script>

<style lang="scss" scoped>
.feedback-modal {
	background: $text-white;

	&-title {
		position: relative;
		@extend %roboto-headline-4;
		width: 100%;
		text-align: center;

		margin-bottom: 0;
	}

	&-form {
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
	}

	&-footer-content {
		display: flex;
		align-items: center;
		flex-direction: column;
		width: 100%;
		gap: 1rem;

		.landing-button {
			margin: 0 auto 1rem;
		}

		p {
			@extend %roboto-note;
		}
	}

	.form-check-input:checked {
		background-color: $primary-dark;
	}
}
</style>
