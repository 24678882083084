<template>
	<router-view />
	<cookie-toast />
</template>

<i18n>
	{
		"ru": {
			"title": "VR Chemistry LAB",
		},
		"en": {
			"title": "Vic’s Science Studio",
		}
	}
</i18n>

<script>
import CookieToast from '@/components/CookieToast.vue';
import FAVICON_RU from '@/assets/images/favicons/vrchemlab.png';
import FAVICON_EN from '@/assets/images/favicons/vics-studio.png';

const ICONS = {
	ru: FAVICON_RU,
	en: FAVICON_EN,
};

export default {
	components: {
		CookieToast,
	},
	created() {
		const title = this.$t('title');
		const icon = ICONS[this.$i18n.locale];
		document.title = title;
		const iconEl = document.querySelector('link[rel="icon"]');
		iconEl.setAttribute('type', 'image/png');
		iconEl.setAttribute('href', icon);
	},
};
</script>

<style lang="scss">
@import 'global';
</style>
