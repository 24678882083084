<template>
	<div class="landing-navbar-items">
		<div class="landing-navbar-items-links landing-navbar-items-links-desktop">
			<landing-button href="#pirces-block" size="small">Купить</landing-button>
			<landing-button @click.stop="handleClick" size="small">Получить демо</landing-button>
		</div>
		<div class="landing-navbar-items-links landing-navbar-items-links-mobile">
			<landing-button href="#pirces-block" link>Купить</landing-button>
			<landing-button @click.stop="handleClick" link>Получить демо</landing-button>
		</div>
		<div class="landing-navbar-items-links">
			<a :href="accountHref">Личный кабинет</a>
			<a
				href="https://affine.vrchemlab.ru/share/108c165a-04af-41f3-8c45-33ef7c78fee5/Byd_asLlHV"
				@click="$emit('closeMenu')"
				target="_blank"
				>База знаний</a
			>
			<a class="landing-navbar-items-email" href="mailto:vr@stemgames.ru" @click="$emit('closeMenu')">
				<div class="landing-navbar-items-email-content">
					<envelope-icon />
					<span>vr@stemgames.ru</span>
				</div>
			</a>
		</div>
	</div>
</template>

<script>
import LandingButton from '@/components/LandingButton.vue';
import EnvelopeIcon from '@/components/icons/EnvelopeIcon.vue';
import { navbarMixin } from '@/mixins';

export default {
	mixins: [navbarMixin],
	methods: {
		handleClick() {
			this.$emit('showFeedback', 'demo');
			this.$emit('closeMenu');
		},
	},
	components: {
		LandingButton,
		EnvelopeIcon,
	},
};
</script>

<style lang="scss" scoped>
.landing-navbar-items {
	display: flex;
	align-items: center;
	width: 100%;

	@include media-breakpoint-down(lg) {
		flex-direction: column;
		align-items: flex-start;
	}

	&-links {
		display: flex;

		&-desktop {
			@include media-breakpoint-down(lg) {
				display: none;
			}
		}

		&-mobile {
			.landing-button {
				@extend %roboto-link-bold;
			}

			@include media-breakpoint-up(lg) {
				display: none;
			}
		}

		&:first-of-type {
			margin-left: auto;
			margin-right: max(calc(100% - 586px - 311px - 523px), 4.5rem);

			@include media-breakpoint-down(lg) {
				margin-left: 0;
				margin-right: 0;
			}
		}

		& > a:not(.landing-button) {
			@extend %roboto-link;
			color: $primary-dark;
			text-decoration: unset;

			&:hover {
				text-decoration: underline;
			}
		}

		& > *:not(:first-child) {
			margin-left: 4.5rem;

			@include media-breakpoint-down(xl) {
				margin-left: 2rem;
			}
		}

		@include media-breakpoint-down(lg) {
			width: 100%;
			flex-direction: column;
			margin: 0;

			& > * {
				text-align: start;
				padding: 1.5rem 0;
				border-bottom: 1px $primary-dark solid;
			}

			& > *:not(:first-child) {
				margin-left: 0;
			}
		}
	}

	&-email {
		text-decoration: unset;

		&:hover {
			text-decoration: underline;
		}

		&-content {
			display: flex;
			align-items: center;

			& > * {
				color: $primary-dark;
			}

			& > span {
				@extend %roboto-link;

				margin-left: 0.5rem;
			}

			@include media-breakpoint-down(lg) {
				padding: 1.5rem 0;

				& > span {
					display: initial;
				}
			}
		}
	}
}
</style>
