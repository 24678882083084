<template>
	<modal
		v-model:show="modalShow"
		:headerLine="false"
		:footerLine="false"
		size="small"
		align="top"
		class="feedback-modal"
	>
		<template v-slot:header>
			<h3 class="feedback-modal-title">Обратная связь</h3>
		</template>
		<template v-slot:body>
			<form class="feedback-modal-form">
				<div class="feedback-modal-form-radio">
					<div v-for="option in options" :key="`option-${option.id}`" class="form-check form-check-inline">
						<input
							:id="option.id"
							:value="option.value"
							v-model="feedback.request"
							class="form-check-input"
							type="radio"
							:name="option.id"
						/>
						<label class="form-check-label" :for="option.id">{{ option.value }}</label>
					</div>
				</div>
				<text-input
					v-model:value="feedback.name"
					inputId="name"
					placeholder="Как к вам обращаться"
					:error="v$.feedback.name.$error"
					:errorMsg="v$.feedback.name.$errors[0]?.$message"
					required
				/>
				<text-input
					v-model:value="feedback.phone"
					inputId="phone"
					:setter="phoneSetter"
					placeholder="Телефон"
					:error="v$.feedback.phone.$error"
					:errorMsg="v$.feedback.phone.$errors[0]?.$message"
					required
				/>
				<text-input
					v-model:value="feedback.email"
					inputId="email"
					placeholder="Почта"
					:error="v$.feedback.email.$error"
					:errorMsg="v$.feedback.email.$errors[0]?.$message"
					required
				/>
				<text-input
					v-model:value="feedback.organisation"
					inputId="organisation"
					placeholder="Название организации"
					:error="v$.feedback.organisation.$error"
					:errorMsg="v$.feedback.organisation.$errors[0]?.$message"
					:required="isBuy ? true : false"
				/>
				<text-input v-if="isBuy" v-model:value="feedback.city" inputId="city" placeholder="Город" />
				<text-input v-if="isBuy" v-model:value="feedback.occupation" inputId="occupation" placeholder="Должность" />
				<text-area-input
					v-if="isBuy"
					v-model:value="feedback.devices"
					inputId="devices"
					placeholder="Используемое оборудование"
				/>
				<text-input
					v-if="isBuy"
					v-model:value="feedback.licencesNumber"
					:setter="licensesNumSetter"
					inputId="licencesNumber"
					placeholder="Сколько лицензий необходимо"
				/>
				<text-area-input
					v-if="isBuy"
					v-model:value="feedback.commentary"
					inputId="commentary"
					placeholder="Ваше сообщение"
				/>
			</form>
		</template>
		<template v-slot:footer>
			<div class="feedback-modal-footer-content">
				<landing-button @click.stop="confirm" outline size="medium">Отправить</landing-button>
				<p>
					Нажимая на кнопку «Отправить», Вы соглашаетесь с
					<router-link :to="{ name: 'Privacy' }"> политикой обработки персональных данных.</router-link>
				</p>
			</div>
		</template>
	</modal>
</template>

<script>
import Modal from '@/components/modals/Modal.vue';
import TextInput from '@/components/inputs/TextInput';
import TextAreaInput from '@/components/inputs/TextAreaInput';
import useVuelidate from '@vuelidate/core';
import LandingButton from '@/components/LandingButton';
import { required, requiredIf, maxLength, helpers } from '@vuelidate/validators';
import { request, gql } from 'graphql-request';

const document = gql`
	mutation createFeedback($data: FeedbackData!) {
		createFeedback(data: $data) {
			success
		}
	}
`;

export default {
	setup() {
		return { v$: useVuelidate() };
	},
	emits: ['update:show', 'update:request', 'submit'],
	data() {
		return {
			defaultFeedback: null,
			feedback: {
				request: 'Купить',
				name: '',
				phone: '',
				email: '',
				organisation: '',
				city: '',
				occupation: '',
				devices: '',
				licencesNumber: null,
				commentary: '',
			},
			options: [
				{ id: 'buy', value: 'Купить' },
				{ id: 'demo', value: 'Получить демо' },
			],
		};
	},
	methods: {
		async confirm() {
			this.v$.$touch();
			if (this.v$.$error) {
				return;
			}

			let data = null;
			if (this.isBuy) {
				data = { ...this.feedback, licencesNumber: +this.feedback.licencesNumber };
			} else {
				const { licencesNumber, ...rest } = this.feedback;
				data = rest;
			}

			const variables = {
				data: data,
			};
			await request(process.env.VUE_APP_API_URL, document, variables);
			this.$emit('submit', this.feedback);
			this.feedback = { ...this.defaultFeedback };
			this.v$.$reset();
			this.modalShow = false;
		},
		phoneSetter(val) {
			let value = val;
			const cleanValue = value.replace(/[^\d+]/g, '');
			if (this.$i18n.locale === 'en') {
				if (!cleanValue.startsWith('+') && cleanValue) value = '+' + cleanValue;
				else value = cleanValue;
			} else {
				if (cleanValue.match(/^[8+]/)) {
					value = cleanValue;
				} else {
					if (cleanValue.startsWith('7')) value = '+' + cleanValue;
					else value = cleanValue ? '+7' + cleanValue : '';
				}
			}

			return value;
		},
		licensesNumSetter(val) {
			return val.replace(/[^\d+]/g, '');
		},
	},
	computed: {
		modalShow: {
			get() {
				return this.show;
			},
			set(show) {
				if (!show) this.v$.$reset();
				this.$emit('update:show', show);
			},
		},
		localRequest: {
			get() {
				return this.request;
			},
			set(request) {
				this.$emit('update:request', request);
			},
		},
		isBuy() {
			return this.feedback.request === 'Купить';
		},
		isDemo() {
			return this.feedback.request === 'Получить демо';
		},
	},
	validations() {
		return {
			feedback: {
				name: {
					required: helpers.withMessage('Требуется заполнить поле', required),
				},
				phone: {
					required: helpers.withMessage('Требуется заполнить поле', required),
					maxLength: helpers.withMessage('Максимально 16 цифр', maxLength(16)),
				},
				email: {
					required: helpers.withMessage('Требуется заполнить поле', required),
				},
				organisation: {
					required: helpers.withMessage(
						'Требуется заполнить поле',
						requiredIf(() => this.isBuy)
					),
				},
			},
		};
	},
	components: {
		Modal,
		TextInput,
		TextAreaInput,
		LandingButton,
	},
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		event: {
			type: String,
			default: '',
		},
		confirmButtonStyle: {
			type: String,
			default: 'success', // 'success' or 'danger'
		},
		request: {
			type: String,
			default: 'buy',
		},
	},
	watch: {
		request(req) {
			this.feedback.request = this.options.find(el => el.id === req).value;
		},
		'feedback.request': {
			handler(req) {
				this.localRequest = this.options.find(el => el.value === req).id;
			},
			deep: true,
		},
	},
	created() {
		this.defaultFeedback = { ...this.feedback };
	},
};
</script>

<style lang="scss" scoped>
.feedback-modal {
	background: $text-white;

	&-title {
		position: relative;
		@extend %roboto-headline-4;
		width: 100%;
		text-align: center;

		margin-bottom: 0;
	}

	&-form {
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
	}

	&-footer-content {
		display: flex;
		align-items: center;
		flex-direction: column;
		width: 100%;
		gap: 1rem;

		.landing-button {
			margin: 0 auto;
		}

		p {
			@extend %roboto-note;
		}
	}
	.form-check-input:checked {
		background-color: $primary-dark;
	}
}
</style>
