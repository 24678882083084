<template>
	<div id="contacts-block" class="contacts-block-en">
		<h1 class="contacts-block-en-title">Contacts</h1>
		<div class="contacts-block-en-contacts">
			<div class="contacts-block-en-contacts-text">
				<div class="contacts-block-en-contacts-text-info">
					<p>Contact information:</p>
					<p>
						Sales and partnership: <a href="mailto:info@vics.studio" class="text-email">info@vics.studio</a>
					</p>
					<p>
						Technical support: <a href="mailto:info@vics.studio" class="text-email">support@vics.studio</a>
					</p>
				</div>
				<div class="contacts-block-en-contacts-text-info">
					<p>Our socials:</p>
					<div class="contacts-block-en-contacts-text-info-socials">
						<a href="https://www.linkedin.com/company/vics-studio/" target="_blank"><linked-in-icon /></a>
						<a href="https://www.youtube.com/channel/UCXu2TsT85MDGFAPBna_Msmg" target="_blank"
							><youtube-icon
						/></a>
					</div>
				</div>
			</div>
			<img src="@/assets/images/paper-airplane-logo-en.svg" alt="contacts" class="contacts-block-en-contacts-image" />
		</div>
	</div>
</template>

<script>
import LinkedInIcon from '@/components/icons/LinkedInIcon.vue';
import YoutubeIcon from '@/components/icons/YoutubeIcon.vue';

export default {
	components: {
		LinkedInIcon,
		YoutubeIcon,
	},
};
</script>

<style lang="scss" scoped>
.contacts-block-en {
    max-width: 48.125rem;
	margin: 0 auto;

	&-title {
		@extend %roboto-headline-3;
		color: $primary-dark;
		text-align: center;
		margin-bottom: 2rem;

        @include media-breakpoint-down(sm) {
            margin-bottom: 3rem;
        }
	}

	&-contacts {
		display: flex;
		justify-content: space-between;
		gap: 5rem;
		padding: 2rem;
		max-width: 48.125rem;
		border: 2px solid $primary-dark;
		border-radius: 1.5rem;

		&-text {
			@extend %roboto-body-1;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			&-info {
				@include media-breakpoint-down(md) {
					&:first-child {
						margin-bottom: 1.5rem;
					}
				}

				p {
					color: $text-secondary;
					margin-bottom: 0;
				}

				p:first-child {
					color: $text-primary;
				}

				&-socials {
                    margin-top: 0.5rem;
					svg {
						height: 2.25rem;
						width: auto;
					}

					a + a {
						margin-left: 1.125rem;
					}
				}
			}
		}

		&-image {
			width: 12.5rem;
			height: auto;

			@include media-breakpoint-down(md) {
				display: none;
			}
		}
	}
}
</style>
