<template>
    <div class="text-input form-floating">
        <input
            :value="localValue"
            @input="handleInput($event)"
            :id="inputId"
            type="text"
            :class="['form-control', { 'is-invalid': error }]"
            :placeholder="textPlaceholder"
            :disabled="disabled"
        />
        <label :for="inputId">{{ textPlaceholder }}</label>
        <div class="invalid-tooltip">{{ errorMsg }}</div>
    </div>
</template>

<script>
import { inputMixin } from '@/mixins';

export default {
    emits: ['update:value'],
    mixins: [inputMixin],
};
</script>

<style lang="scss">
.text-input {

    input {
        // @extend %jura-headline-3;
        // border: 1px solid $text-secondary;
        // border-radius: 1.5rem;
    }
}
</style>
