<template>
	<svg 
	xmlns="http://www.w3.org/2000/svg"
	xml:space="preserve"
	width="850px"
	height="850px"
	version="1.1"
	style="shape-rendering:geometricPrecision;
	text-rendering:geometricPrecision;
	image-rendering:optimizeQuality;
	fill-rule:evenodd;
	clip-rule:evenodd"
	class="vicicon-css"
	viewBox="0 0 844.03 844.03"
 xmlns:xlink="http://www.w3.org/1999/xlink"
 xmlns:xodm="http://www.corel.com/coreldraw/odm/2003">
 <g id="Layer_x0020_1">
  <metadata id="CorelCorpID_0Corel-Layer"/>
  <path class="fil0" d="M481.48 384.41c-16.44,-1.35 -30.84,10.87 -32.16,27.3 -1.31,16.43 10.94,30.85 27.39,32.2 16.42,1.36 30.82,-10.86 32.14,-27.29 1.31,-16.44 -10.94,-30.85 -27.37,-32.21zm-83.24 205.28c-38.54,3.18 -72.3,-25.48 -75.39,-64.01 -3.1,-38.53 25.64,-72.34 64.18,-75.53 38.54,-3.17 72.29,25.49 75.39,64.02 3.1,38.53 -25.64,72.35 -64.18,75.52zm-122.43 -275.58c-65.7,37.27 -112.35,105.35 -118.85,186.38 -10.46,130.15 86.58,244.35 216.74,255.08 9.43,0.78 18.78,0.97 28.02,0.65l32.53 64.48c0.79,1.57 2.59,2.31 4.25,1.75l92.01 -30.61c1.66,-0.57 2.66,-2.23 2.36,-3.96l-12.22 -68.9c11.96,-7.68 23.21,-16.44 33.58,-26.13l66.57 27.43c1.62,0.67 3.46,0.05 4.36,-1.46l49.6 -83.33c0.9,-1.51 0.55,-3.42 -0.81,-4.54l-54.34 -44.16c3.91,-13.38 6.68,-27.3 8.18,-41.63l68.48 -22.85c1.43,-0.48 2.36,-1.78 2.4,-3.23l0 -0.16c0,-0.19 -0.02,-0.38 -0.06,-0.56l-16.96 -95.48c-0.31,-1.73 -1.82,-2.95 -3.58,-2.9l-70.35 2.12c-5.87,-12.82 -12.86,-25.06 -20.85,-36.58l37.04 -62.34c0.91,-1.51 0.56,-3.42 -0.8,-4.53l-75.27 -61.13c-1.37,-1.11 -3.31,-1.05 -4.61,0.14l-50.4 46.3c-18.5,-14.83 -28.95,-36.62 -28.95,-61.32l0 -144.81c0,-3.68 3,-6.68 6.69,-6.68l3.33 0c3.69,0 6.68,-3.01 6.68,-6.69l0 -56.08c0,-3.68 -2.99,-6.68 -6.68,-6.68 -56,0 -112,0 -168.01,0 -3.68,0 -6.68,3 -6.68,6.68l0 56.08c0,3.68 3,6.69 6.68,6.69l46.87 0c3.89,0 7.04,3.15 7.04,7.03 0,3.89 -3.15,7.04 -7.04,7.04l-36.85 0 0 140.02c0,29.29 -14.63,54.42 -40.1,68.87z"/>
  <rect class="fil1" width="844.03" height="844.03"/>
 </g>
</svg>
</template>

<script>
export default {
	name: 'ChemlabIcon',
};
</script>

<style lang="scss" scoped>
.vicicon-css {
	width: 2.5rem;
	height: 2.5rem;
}
.fil1 {fill:none}
.fil0 {fill:black}
</style>
