export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VR Chemistry Lab"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всегда есть место для эксперимента"])}
      },
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vic’s Science Studio"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is always room for the experiment"])}
      }
    }
  })
}
