<template>
    <teleport to="#base-modal">
        <div :class="['vr-modal', { 'vr-modal--display': show }, $attrs.class]">
            <div :class="['vr-modal__wrapper', `vr-modal__wrapper--align-${align}`]">
                <div v-click-outside="closeModalOnClick" :class="['vr-modal__wrapper__content', `vr-modal__wrapper__content--${size}`]">
                    <slot />
                </div>
            </div>
        </div>
    </teleport>
</template>

<script>
export default {
    emits: ['update:show'],
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        // possible values (width in brackets): small (800px), medium (1100px), large (1400px)
        size: {
            type: String,
            default: 'medium',
        },
        // possible values: top, center
        align: {
            type: String,
            default: 'top',
        },
        staticBackdrop: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        closeModal() {
            this.$emit('update:show', false);
        },
        closeModalOnClick() {
            if (!this.staticBackdrop) this.closeModal();
        },
    },
    watch: {
        show(val) {
            if (val) {
                document.body.classList.add('body--overflow-hidden');
            } else {
                document.body.classList.remove('body--overflow-hidden');
            }
        },
    }
};
</script>

<style lang="scss" scoped>
.vr-modal {
    display: none;
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: $modal-backdrop;

    &--display {
        display: block;
    }

    &__wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        max-height: calc(100vh - 2rem);
        margin: 0 2rem;

        @include media-breakpoint-down(sm) {
            flex-direction: column;
            top: 0.5rem;
            max-height: calc(100vh - 0.5rem);
            margin: 0;
        }

        &--align-top {
            .vr-modal__wrapper__content {
                margin-top: 2rem;

                @include media-breakpoint-down(sm) {
                    margin-top: 0;
                }
            }
        }

        &--align-center {
            .vr-modal__wrapper__content {
                margin-top: 8rem;

                @include media-breakpoint-down(sm) {
                    margin-top: 0;
                }
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            overflow-y: hidden;
            background-color: $text-white;
            border-radius: 0.25rem;

            &--small {
                width: 50rem;
            }

            &--medium {
                width: 68.75rem;
            }

            &--large {
                width: 87.5rem;
            }

            &--small,
            &--medium,
            &--large {
                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
            }
        }
    }
}
</style>
