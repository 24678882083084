<template>
	<svg width="112" height="76" viewBox="0 0 112 76" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M89.499 67.938C89.499 70.6104 87.4256 72.7758 84.8695 72.7758H7.71359C5.15754 72.7758 3.08543 70.6104 3.08543 67.938V66.3259H33.3088L35.9411 69.078C36.2311 69.3797 36.6242 69.5501 37.0333 69.5501H55.5499C55.9603 69.5501 56.352 69.3797 56.6421 69.078L57.888 67.776H46.6933C44.8177 67.776 43.0571 67.2479 41.525 66.3259H37.6723L35.04 63.5752C34.7486 63.2735 34.3569 63.1031 33.9465 63.1003H6.17087V14.7256H65.9104V11.5013H4.62815C3.77658 11.5013 3.08543 12.2222 3.08543 13.1135V63.1003H1.54138C0.689811 63.1003 0 63.8225 0 64.7124V67.938C0.00401053 72.3888 3.45574 75.9958 7.71359 76H84.8695C89.1287 75.9958 92.5805 72.3888 92.5858 67.938V67.776H89.499V67.938Z"
			fill="currentColor"
		/>
		<path
			d="M86.4128 14.7258V23.2474H87.3004C88.1052 23.2474 88.8472 23.4821 89.4995 23.8677V13.1137C89.4995 12.2224 88.807 11.5002 87.9555 11.5002H75.7701V14.7258H86.4128Z"
			fill="currentColor"
		/>
		<path
			d="M45.0882 61.93C44.3316 61.93 43.7179 61.2874 43.7179 60.4981C43.7179 59.7074 44.3329 59.0648 45.0882 59.0648C45.8422 59.0648 46.4585 59.7074 46.4585 60.4981C46.4585 61.2874 45.8435 61.93 45.0882 61.93ZM45.0882 56.5461C43.0014 56.5461 41.3063 58.3174 41.3063 60.4981C41.3063 62.676 43.0014 64.4502 45.0882 64.4502C47.1737 64.4502 48.8688 62.676 48.8688 60.4981C48.8688 58.3174 47.1737 56.5461 45.0882 56.5461Z"
			fill="currentColor"
		/>
		<path
			d="M45.0882 37.6971C44.3316 37.6971 43.7179 37.0545 43.7179 36.2638C43.7179 35.4731 44.3329 34.8305 45.0882 34.8305C45.8422 34.8305 46.4585 35.4731 46.4585 36.2638C46.4585 37.0545 45.8435 37.6971 45.0882 37.6971ZM45.0882 32.3118C43.0014 32.3118 41.3063 34.0831 41.3063 36.2638C41.3063 38.4417 43.0014 40.2173 45.0882 40.2173C47.1737 40.2173 48.8688 38.4417 48.8688 36.2638C48.8688 34.0831 47.1737 32.3118 45.0882 32.3118Z"
			fill="currentColor"
		/>
		<path
			d="M96.6218 61.93C95.8665 61.93 95.2515 61.2874 95.2515 60.4981C95.2515 59.7074 95.8665 59.0648 96.6218 59.0648C97.3784 59.0648 97.992 59.7074 97.992 60.4981C97.992 61.2874 97.3784 61.93 96.6218 61.93ZM96.6218 56.5461C94.5363 56.5461 92.8398 58.3174 92.8398 60.4981C92.8398 62.676 94.5363 64.4502 96.6218 64.4502C98.7086 64.4502 100.405 62.676 100.405 60.4981C100.405 58.3174 98.7086 56.5461 96.6218 56.5461Z"
			fill="currentColor"
		/>
		<path
			d="M96.6218 37.6971C95.8665 37.6971 95.2515 37.0545 95.2515 36.2638C95.2515 35.4731 95.8665 34.8305 96.6218 34.8305C97.3784 34.8305 97.992 35.4731 97.992 36.2638C97.992 37.0545 97.3784 37.6971 96.6218 37.6971ZM96.6218 32.3118C94.5363 32.3118 92.8398 34.0831 92.8398 36.2638C92.8398 38.4417 94.5363 40.2173 96.6218 40.2173C98.7086 40.2173 100.405 38.4417 100.405 36.2638C100.405 34.0831 98.7086 32.3118 96.6218 32.3118Z"
			fill="currentColor"
		/>
		<path
			d="M68.7022 41.8155C68.0779 41.5711 67.3841 41.9021 67.1488 42.5517L63.9618 51.4365C63.7318 52.0764 63.1383 52.5066 62.4779 52.5066C61.8228 52.5066 61.2293 52.0764 60.9993 51.4365L57.8123 42.5517C57.5783 41.9021 56.8818 41.5711 56.2602 41.8155C55.6372 42.06 55.3204 42.785 55.5544 43.4374L58.7414 52.3222C59.3229 53.9385 60.8229 55.0254 62.4859 55.0254C64.1382 55.0254 65.6382 53.9385 66.2197 52.3222L69.4067 43.4374C69.6407 42.785 69.3238 42.06 68.7022 41.8155Z"
			fill="currentColor"
		/>
		<path
			d="M84.9441 45.6871C84.9441 43.5092 83.2463 41.735 81.1621 41.735H73.4312C72.7654 41.735 72.2267 42.2994 72.2267 42.9951V53.7658C72.2267 54.4615 72.7654 55.0259 73.4312 55.0259C74.0969 55.0259 74.637 54.4615 74.637 53.7658V44.2551H81.1621C81.9175 44.2551 82.5324 44.8978 82.5324 45.6871C82.5324 46.4777 81.9161 47.1204 81.1621 47.1204H78.6088C77.9016 47.1204 77.2706 47.5883 77.0366 48.284C76.804 48.9811 77.0153 49.7593 77.5687 50.2203L82.9856 54.7479C83.2075 54.9351 83.4722 55.0259 83.7382 55.0259C84.0912 55.0259 84.4414 54.8638 84.6794 54.5523C85.0951 54.0089 85.0109 53.2168 84.4909 52.7823L80.7344 49.6391H81.1621C83.2463 49.6391 84.9441 47.8677 84.9441 45.6871Z"
			fill="currentColor"
		/>
		<path
			d="M109.588 48.38C109.588 49.1707 108.975 49.8133 108.218 49.8133H106.846V41.5614H108.218C108.975 41.5614 109.588 42.204 109.588 42.9961V48.38ZM104.436 57.8041C104.436 63.0484 100.353 67.3161 95.3349 67.3161H46.3757C41.3572 67.3161 37.2744 63.0484 37.2744 57.8041V38.956C37.2744 33.7117 41.3572 29.4453 46.3757 29.4453H86.3166C86.9823 29.4453 87.5211 28.881 87.5211 28.1853C87.5211 27.4896 86.9823 26.9266 86.3166 26.9266H46.3757C45.9706 26.9266 45.5682 26.9475 45.1738 26.9922C45.6658 25.2977 47.1738 24.06 48.9531 24.06H92.7575C94.5368 24.06 96.0448 25.2977 96.5354 26.9922C96.141 26.9489 95.74 26.9266 95.3349 26.9266H91.4701C90.8043 26.9266 90.2643 27.4896 90.2643 28.1853C90.2643 28.881 90.8043 29.4453 91.4701 29.4453H95.3349C100.353 29.4453 104.436 33.7117 104.436 38.956V57.8041ZM33.4925 49.8133C32.7358 49.8133 32.1222 49.1707 32.1222 48.38V42.9961C32.1222 42.204 32.7358 41.5628 33.4925 41.5628H34.8641V49.8133H33.4925ZM108.218 39.0426H106.846V38.956C106.846 33.6964 103.599 29.2176 99.0888 27.5874C98.7987 24.2011 96.0715 21.5412 92.7575 21.5412H77.214V2.60513C77.214 1.16763 76.095 -0.000244141 74.7208 -0.000244141H66.9898C65.6155 -0.000244141 64.4966 1.16763 64.4966 2.60513V17.4145C64.4966 18.1102 65.0367 18.6746 65.7024 18.6746C66.3682 18.6746 66.9069 18.1102 66.9069 17.4145V2.60513C66.9069 2.55762 66.9444 2.51852 66.9898 2.51852H74.7208C74.7662 2.51852 74.8037 2.55762 74.8037 2.60513V21.5412H48.9531C45.6404 21.5412 42.9132 24.2025 42.6205 27.5874C38.1113 29.2176 34.8641 33.6964 34.8641 38.956V39.0426H33.4925C31.4084 39.0426 29.7106 40.8154 29.7106 42.9961V48.38C29.7106 50.5607 31.4084 52.3321 33.4925 52.3321H34.8641V57.8041C34.8641 64.437 40.0283 69.8335 46.3757 69.8335H95.3336C101.682 69.8335 106.846 64.437 106.846 57.8041V52.3321H108.218C110.302 52.3321 112 50.5607 112 48.38V42.9961C112 40.8154 110.302 39.0426 108.218 39.0426Z"
			fill="currentColor"
		/>
	</svg>
</template>

<script>
export default {
	name: 'VrHeadsetIcon',
};
</script>
