<template>
    <div class="page-content about-page lead">
        <div class="about-page__header">
            <img :src="logoPath" />
            <h3>Соглашение с конечным пользователем</h3>
        </div>
        <h5>Термины</h5>
        <ul>
            <li>Активация - привязка Лицензионного ключа к аккаунту Конечного Пользователя на сайте Программы
                vrchemlab.ru, направленное на подтверждение наличия права использования Программы на персональном
                компьютере или ином устройстве.</li>
            <li>Клиентская часть ПО – часть ПО, подлежащая установке на устройство Пользователя, необходимая для
                использования ПО, включая получение прав использования данных и команд, размещенных на серверах.</li>
            <li>Конечный пользователь, Пользователь – физическое или юридическое лицо, в том числе представитель
                юридического лица, который впервые производит активацию ПО, приобретающее неисключительные права
                (неисключительную лицензию) на воспроизведение ПО без последующей возможности распространения ПО.</li>
            <li>Лицензионный ключ – техническое средство защиты от незаконного использования ПО, представляющее собой
                последовательность символов, которая передается Лицензиату для Активации ПО.</li>
            <li>Пользовательское Соглашение – соглашение, с которым Пользователь обязан ознакомиться до начала работы с
                ПО при его Активации, а также соблюдать правила, указанные в нем, на протяжении всего лицензионного
                срока. Соглашение размещено по адресу vrchemlab.ru .</li>
            <li>Программное Обеспечение (ПО) - комплекс программ под условным наименованием «VR Chemistry Lab»,
                созданный на базе следующих программ для ЭВМ:</li>
            <ol>
                <li class="letter-list">«ВиАр химическая лаборатория», (Свидетельство о государственной регистрации
                    программы для ЭВМ № 2019613542 от 18.03.2019);</li>
                <li class="letter-list">«ВиАр химическая лаборатория – физико-химическая модель 1.0», (Свидетельство о
                    государственной регистрации программы для ЭВМ № 2020661980 от 05.10.2020);</li>
                <li class="letter-list">«ВиАр химическая лаборатория – личный кабинет 1.0», (Свидетельство о
                    государственной регистрации программы для ЭВМ № 2020618748 от 04.08.2020);</li>
            </ol>
            <li>Разработчик - ООО «СТЕМ-игры», имеющее исключительное право на ПО, или имеющее право на основании закона
                или договора распоряжаться иными результатами интеллектуальной деятельности, которые входят или могут
                входить в состав ПО.</li>
        </ul>
        <div class="agreement">
            <ol>
                <li>
                    <h4>Общие положения</h4>
                    <ol>
                        <li>Общество с ограниченной ответственностью «СТЕМ-игры» (далее - ООО «СТЕМ-игры»; ИНН
                            9705097051), предлагает пользователю ПО (далее – Пользователь) - использовать своё ПО на
                            условиях, изложенных в настоящем Пользовательском соглашении (далее — «Соглашение», «ПС»).
                            Соглашение вступает в силу с момента выражения Пользователем согласия с его условиями в
                            порядке, предусмотренном п. 3 Соглашения.</li>
                        <li>Все существующие на данный момент компоненты (версии) ПО Разработчика и других компаний,
                            условия использования которых ссылаются на данное Соглашение (далее – ПО), а также любое
                            улучшение их и/или добавление новых является предметом настоящего Соглашения.</li>
                        <li>Начиная использовать ПО, Пользователь считается принявшим условия Соглашения в полном
                            объеме, без всяких оговорок и исключений. В случае несогласия Пользователя с какими-либо из
                            положений Соглашения Пользователь не вправе использовать ПО. Актуальные версии юридически
                            значимых документов размещены по ссылке на сайте: vrchemlab.ru. Если Пользователь не
                            согласен с актуальной версией Соглашения, он обязан прекратить использование ПО.</li>
                        <li>В случае, если юридическое лицо или индивидуальный предприниматель, в том числе их
                            уполномоченные представители, произвели активацию ПО, что подразумевает ознакомление с
                            настоящим Соглашением, они обязаны довести положения настоящего Соглашения до всех третьих
                            лиц, которые будут допущены к использованию ПО.</li>
                        <li>Разработчик вправе в любое время в одностороннем порядке изменять условия настоящего
                            Соглашения. Такие изменения вступают в силу по истечении 3 (трех) дней с момента размещения
                            новой версии Соглашения на странице Сайта vrchemlab.ru. Пользователь самостоятельно
                            отслеживает изменения Соглашения и знакомится с действующей редакцией Соглашения. При
                            несогласии Пользователя с внесенными изменениями он обязан отказаться от использовать ПО.
                        </li>
                    </ol>
                </li>
                <li>
                    <h4>Объем предоставляемых прав</h4>
                    <ol>
                        <li>Разработчик предоставляет Пользователю личную, ограниченную, непередаваемую, отзываемую и
                            неисключительную лицензию на использование ПО, к которому Пользователь имеете доступ, при
                            условии соблюдения положений настоящего Соглашения.</li>
                        <li>Пользователь не может использовать, копировать, модифицировать или распространять ПО, если
                            это явным образом не разрешено Разработчиком. Пользователь не вправе декомпилировать,
                            пытаться извлечь или иным образом использовать исходный код или другие данные из ПО, если
                            это явным образом не разрешено Разработчиком. Разработчик или его Лицензиары владеют и
                            оставляют за собой все права, связанные с интеллектуальной собственностью. Пользователь
                            обязуется не запускать ПО с неразрешенными подключаемыми модулями, не создавать производные
                            продукты какой-либо формы (в том числе подключаемые модули), не получать доступ к ПО и
                            связанным с ним системам через неразрешенные сторонние инструменты/службы.</li>
                        <li>Исключительное право на все объекты, сценарии, прочие результаты интеллектуальной
                            деятельности, созданные в процессе использования ПО, признается за Разработчиком.</li>
                        <li>Пользователь может:
                            <ul>
                                <li>воспроизводить ПО посредством записи (инсталляции) ПО на Устройство и запуска
                                    записанной копии;</li>
                                <li>демонстрировать ПО третьим лицам соблюдая все правила использования и ограничения,
                                    предусмотренные настоящим Cоглашением;</li>
                            </ul>
                        </li>
                        <li>Воспроизведение Клиентской части Программы происходит путем ее установки на устройство,
                            обладающее следующими необходимыми техническими характеристиками:</li>
                        <ul>
                            <li>Оборудование виртуальной реальности - VR-шлем и контроллеры с шестью степенями свободы
                                (6dof):</li>
                            <li>или автономный VR-шлем:</li>
                            <ul>
                                <li>Pico Neo 3 / Link / Pro / Pro Eye</li>
                                <li>Pico 4 / Pro / Enterprise </li>
                                <li>HTC Vive Focus Plus </li>
                                <li>HTC Vive Focus 3 </li>
                                <li>HTC Vive XR Elite</li>
                                <li>Oculus Quest 1/2/3 </li>
                            </ul>
                            <li>или подключаемое к персональному компьютеру (рабочей станции):
                                <ul>
                                    <li>HTC Vive</li>
                                    <li>HTC Vive Pro / Pro Eye</li>
                                    <li>HTC Vive Pro 2</li>
                                    <li>HTC Vive Cosmos / Elite</li>
                                    <li>Oculus Rift / Rift S</li>
                                    <li>HP Reverb / Reverb G2</li>
                                    <li>Dell Visor</li>
                                    <li>Samsung HMD Odyssey+</li>
                                    <li>Acer AH101</li>
                                </ul>
                            </li>
                            <li>Минимальные требования к рабочей станции - процессор i5-8300, ОЗУ - 6 Гб, видеокарта -
                                nVidia GeForce 1060, 2 Gb места на жестком диске, порты - usb 3.0 + HDMI, операционная
                                система - Windows 10;</li>
                            <li>Стабильное подключение к интернету, не менее 2 МБит/с. Точка доступа в месте нахождения
                                всех устройств должна поддерживать их стабильное соединение. На каждое устройство должно
                                быть выделено не менее 0,5 мегабит в секунду. Роутер не должен обрывать соединение с
                                устройствами. Необходимо также учитывать загрузку сети сторонними устройствами, которые
                                имеют доступ к данному WiFi.</li>
                            <li>Пользователь обязуется не устанавливать без разрешения Разработчика ПО на устройствах
                                других типов, явно не одобренных Разработчиком.</li>
                        </ul>
                    </ol>
                </li>
                <li>
                    <h4>Заверения и гарантии</h4>
                    <ol>
                        <li>Разработчик гарантирует, что:</li>
                        <ul>
                            <li>он обладает правом на заключение настоящего Соглашения, действует в пределах прав и
                                полномочий, предоставленных ему Правообладателями стороннего ПО в рамках соответствующих
                                Лицензионных договоров, и на момент передачи Пользователю прав по настоящему Соглашению
                                сторонние РИДы не имеют запретов на их предоставление, не являются предметом исков
                                третьих лиц. Разработчику ничего не известно о правах третьих лиц, которые могли быть
                                нарушены предоставлением лицензии на ПО по настоящему Договору;</li>
                            <li>в ПО не используются никакие элементы, нарушающие права третьих лиц, ПО не нарушает
                                действующее законодательство Российской Федерации.</li>
                        </ul>
                        <li>Техническая документация, прилагаемая к ПО, содержит необходимую и достаточную информацию
                            для использования ПО.</li>
                        <li>Пользователь подтверждает и соглашается, что ПО принимается и используется на условиях «как
                            есть». Разработчик не предоставляет никаких гарантий в отношении безошибочной и
                            бесперебойной работы ПО, соответствия ПО конкретным целям и ожиданиям Пользователя,
                            сохранности файлов и/или данных Пользователя, а также не предоставляет никаких иных
                            гарантий, прямо не указанных в настоящем Соглашении.</li>
                        <li>Если при использовании ПО будут обнаружены ошибки, Разработчик предпримет меры для их
                            исправления. Стороны соглашаются, что точное определение срока устранения ошибок не может
                            быть установлено, так как ПО взаимодействует с другими программами для ЭВМ сторонних
                            разработчиков: операционной системой и аппаратными ресурсами персонального компьютера – в
                            связи с чем, работоспособность и время устранения проблем в полной мере не зависят только от
                            Разработчика.</li>
                        <li>Разработчик не несет ответственности за какую бы то ни было упущенную прибыль, потерю
                            доходов или оборота с продаж, утрату данных, а также за затраты на приобретение замещающих
                            товаров или услуг, порчу имущества, причинение вреда здоровью, приостановку работы, потерю
                            коммерческой информации или какие бы то ни было другие случаи ущерба, в том числе прямого
                            и/или косвенного вызванного использованием ПО или невозможностью его использования.</li>
                    </ol>
                </li>
                <li>
                    <h4>Пользователь обязуется</h4>
                    <ol>
                        <li>Ознакомиться с возможными негативными последствиями для здоровья во время использования ПО,
                            связанными с виртуальным перемещением в 3D-пространстве, и ограничениями, предусмотренными
                            производителями используемого оборудования виртуальной реальности, в том числе для лиц,
                            страдающих эпилепсией; любыми другими видами припадков; а также не достигших возраста 12
                            лет; беременных женщин, размещенных <router-link :to="{ name: 'SafetyPrecautions' }">по
                            ссылке.</router-link></li>
                        <li>В случае обнаружения ошибок и(или) иных неисправностей при использовании ПО сообщить об этом
                            посредством электронной почты: <a
                                href="mailto:support@stemgames.ru">support@stemgames.ru</a></li>
                        <li>Самостоятельно принимать меры по обеспечению безопасности данных для авторизации в ПО и
                            предотвращению его несанкционированного использования третьими лицами. Не раскрывать и не
                            передавать третьим лицам Лицензионный ключ, а также свои данные Учетной записи пользователя,
                            с помощью которых возможна авторизация (аутентификация) пользователя в Продукте.</li>
                        <li>Самостоятельно принимать меры, необходимые для поддержания оборудования в состоянии,
                            соответствующем техническим требованиям необходимым для использования ПО, в том числе
                            осуществлять загрузку обновлений ПО, обеспечивать наличие бесперебойного подключения к
                            Интернету и др.</li>
                        <li>Незамедлительно сообщить Разработчику о любых фактах несанкционированного использования
                            Продукта, взлома и совершения иных подобных действий.</li>
                        <li>Ни при каких условиях не удалять или не изменять внешний вид информации и сведения об
                            авторских правах, правах на товарные знаки или регистрационную информацию, указанную в ПО.
                        </li>
                    </ol>
                </li>
                <li>
                    <h4>Отмена, приостановка или прекращение предоставления Программного обеспечения</h4>
                    <ol>
                        <li>Разработчик, в том числе с использованием средств защиты, может приостанавливать или
                            отключать работу ПО полностью или частично при обнаружении нарушений, истечении или
                            прекращении действия срочной Лицензии, невозможности обмена информацией между ПО и серверами
                            Разработчика через сеть Интернет при работе средств защиты авторских прав, нарушении условий
                            настоящего соглашения. Какие-либо предварительные уведомления о приостановлении/отключении
                            работы Программного продукта не производятся.</li>
                        <li>Правообладатель вправе в одностороннем (внесудебном) порядке немедленно расторгнуть
                            настоящее Соглашение либо лицензионный договор в случае нарушения Пользователем любого из
                            положений данного Соглашения.</li>
                    </ol>
                </li>
                <li>
                    <h4>Разрешение споров</h4>
                    <ol>
                        <li>Все споры и разногласия, возникшие из Соглашения, решаются Сторонами путем переговоров с
                            использованием досудебного (претензионного) порядка.</li>
                        <li>Стороны могут направить претензии в бумажном виде, одним из следующих способов по своему
                            выбору:</li>
                        <ul>
                            <li>по почте заказным письмом с уведомлением о вручении лично адресату и описью вложения.
                                Сообщение считается доставленным в момент его поступления в отделение почты получателя;
                            </li>
                            <li>курьером. В этом случае представитель адресата претензии, уполномоченный принимать
                                документы, обязан расписаться на копии претензии в подтверждение ее получения;</li>
                        </ul>
                        <li>По электронной почте <a href="mailto:vr@stemgames.ru">vr@stemgames.ru.</a></li>
                        <li>Претензии должны направляться по реквизитам: Общество с ограниченной ответственностью
                            «СТЕМ-игры» (ООО «СТЕМ-игры») по адресу: 119021, г. Москва, ул. Льва Толстого, д. 5/1, офис
                            А-308 </li>
                        <li>В случае невозможности урегулировать спор в претензионном порядке в течение 30 (тридцати)
                            календарных дней с даты начала таких переговоров, такой спор подлежит передаче на
                            рассмотрение по месту нахождения ООО «СТЕМ-игры»</li>
                    </ol>
                </li>
            </ol>
        </div>
    </div>
</template>


<script>
export default {
    computed: {
        logoPath() {
            const name = this.$i18n.locale === 'ru' ? 'vrchemlab' : 'vics-studio';
            return require(`@/assets/images/${name}-logo.png`);
        },
    },
};
</script>

<style lang="scss" scoped>
.about-page {
    padding-left: 4rem;

    &__header {
        display: flex;
        align-items: flex-end;
        gap: 0.5rem;
        padding-left: 2rem;
        margin-bottom: 1rem;

        img {
            max-width: 3rem;
            height: auto;
        }

        h3 {
            font-weight: bold;
            margin-bottom: 0;
        }
    }
}

.agreement {
    ol {
        counter-reset: index;
        list-style-type: none;
    }

    ol>li::before {
        counter-increment: index;
        content: counters(index, '.', decimal) ' ';
    }

    ul li {
        counter-increment: none;
        list-style-type: disc;
    }

    h4 {
        display: inline;
        margin-top: 1rem;
    }
}

$letters: "а", "б", "в";

@each $letter in $letters {
    $i: index($letters, $letter);

    .letter-list:nth-child(#{$i})::marker {
        content: $letter(") ");
    }
}
</style>