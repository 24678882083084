<template>
	<div class="about-block-en">
		<h3 class="about-block-en-title">Advantages of Vic’s Science Studio</h3>
		<div
			v-for="(block, idx) in blocks"
			:key="`block-${idx}`"
			:class="['about-block-en-block', { 'about-block-en-block-reverse': idx % 2 !== 0 }]"
		>
			<div class="about-block-en-block-text">
				<p>{{ block.title }}</p>
				<ul>
					<li v-for="(item, idx) in block.list" :key="`list-item-${idx}`">{{ item }}</li>
				</ul>
			</div>
			<div class="about-block-en-block-cover" :style="{ 'background-image': `url(${block.image})` }">
				<img :src="block.image" :alt="block.alt" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			blocks: [
				{
					title: 'For chemistry teachers:',
					list: [
						'Always safe environment for students',
						'Access to unavailable or expensive equipment in VR laboratory',
						'Problem-based learning with hazardous chemicals or quantitative laboratory work',
						'Ability to organize lab work remotely',
					],
					image: require('@/assets/images/about-block-3.png'),
					alt: 'For teachers',
				},
				{
					title: 'For students:',
					list: [
						'Training skills ready to use in real chemical lab',
						'Accessible for students with disabilities and allergies',
						'Freedom in decision-making during an experiment',
					],
					image: require('@/assets/images/about-block-1.png'),
					alt: 'For students',
				},
			],
		};
	},
};
</script>

<style lang="scss">
.about-block-en {
	margin-left: -1.5rem;
	margin-right: -1.5rem;

	&-title {
		@extend %roboto-headline-3;
		color: $primary-dark;
		text-align: center;
		margin: 0 1.5rem 6rem 1.5rem;

		@include media-breakpoint-down(lg) {
			margin-bottom: 5rem;
		}

		@include media-breakpoint-down(sm) {
			margin-bottom: 4rem;
		}
	}

	&-block {
		position: relative;
		display: flex;
		justify-content: center;
		overflow: hidden;

		&-text {
			display: flex;
			flex-direction: column;
			justify-content: center;
			color: $text-secondary;
			margin-bottom: 0;
			max-width: 39rem;
			margin-left: 1.5rem;

			@include media-breakpoint-down(lg) {
				max-width: 22.875rem;
			}

			p {
				@extend %roboto-body-2-bold;
				padding-left: 2rem;
				color: $primary-dark;

				@include media-breakpoint-down(sm) {
					margin-bottom: 0.5rem;
				}
			}

			ul {
				@extend %roboto-body-2;
			}

			@include media-breakpoint-down(sm) {
				width: 100%;
				height: 12.5rem;
				margin-right: 1.5rem;
				color: $text-primary;
			}
		}

		&-reverse {
			flex-direction: row-reverse;

			.about-block-en-block-text {
				margin-left: 0;
				margin-right: 1.5rem;

				@include media-breakpoint-down(sm) {
					margin-left: 1.5rem;
				}
			}

			.about-block-en-block-cover {
				justify-content: flex-end;
			}
		}

		&-cover {
			display: flex;
			justify-content: flex-start;
			max-width: 45.625rem;
			flex-grow: 1;
			overflow: hidden;

			@include media-breakpoint-up(sm) {
				background-image: none !important; // ovverrides inline style
			}

			&--reverse {
				background-position: right;
			}

			@include media-breakpoint-down(sm) {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background-size: contain;
				background-position: top right;
				background-repeat: no-repeat;
				opacity: 0.25;

				img {
					display: none;
				}
			}
		}
	}
}
</style>
