<template>
    <div class="about-block">
        <h3 class="about-block-title">Преимущества VR Chemistry Lab</h3>
        <div v-for="(block, idx) in blocks" :key="`block-${idx}`" :class="['about-block-block', { 'about-block-block-reverse': idx % 2 !== 0 }]">
            <div class="about-block-block-text">
                <p>{{ block.text }}</p>
            </div>
            <div class="about-block-block-cover" :style="{ 'background-image': `url(${block.image})` }">
                <img :src="block.image" :alt="block.alt" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            blocks: [
                {
                    text: 'VR обеспечивает эффект присутствия и глубокое погружение в образовательное содержимое для получения безопасного опыта.',
                    image: require('@/assets/images/about-block-1.png'),
                    alt: 'Эффект присутствия',
                },
                {
                    text: 'Специально разработанная химическая модель дает пользователю свободу действий и химически-корректный результат.',
                    image: require('@/assets/images/about-block-2.png'),
                    alt: 'Химическая модель',
                },
                {
                    text: 'В VR лаборатории ученик получает опыт экспериментальной работы, решения проблемных и поисковых задач в комфортном ученику темпе.',
                    image: require('@/assets/images/about-block-3.png'),
                    alt: 'Экспериментальные работы',
                },
            ]
        };
    },
};
</script>

<style lang="scss">
.about-block {
    margin-left: -1.5rem;
    margin-right: -1.5rem;

    &-title {
        @extend %roboto-headline-3;
        color: $primary-dark;
        text-align: center;
        margin: 0 1.5rem 6rem 1.5rem;

        @include media-breakpoint-down(lg) {
            margin-bottom: 5rem;
        }

        @include media-breakpoint-down(sm) {
            margin-bottom: 4rem;
        }
    }

    &-block {
        position: relative;
        display: flex;
        justify-content: center;
        overflow: hidden;

        &-text {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 1.5rem;

            @include media-breakpoint-down(sm) {
                width: 100%;
                height: 12.5rem;
                margin-right: 1.5rem;
            }

            p {
                @extend %roboto-body-2;
                max-width: 37.5rem;
                color: $text-secondary;
                margin-bottom: 0;

                @include media-breakpoint-down(lg) {
                    max-width: 22.875rem;
                }

                @include media-breakpoint-down(sm) {
                    color: $text-primary;
                }
            }
        }

        &-reverse {
            flex-direction: row-reverse;

            .about-block-block-text {
                margin-left: 0;
                margin-right: 1.5rem;

                @include media-breakpoint-down(sm) {
                    margin-left: 1.5rem;
                }
            }

            .about-block-block-cover {
                justify-content: flex-end;
            }
        }

        &-cover {
            display: flex;
            justify-content: flex-start;
            max-width: 45.625rem;
            flex-grow: 1;
            overflow: hidden;

            @include media-breakpoint-up(sm) {
                background-image: none !important; // ovverrides inline style
            }

            &--reverse {
                background-position: right;
            }

            @include media-breakpoint-down(sm) {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-size: contain;
                background-position: top right;
                background-repeat: no-repeat;
                opacity: 0.25;

                img {
                    display: none;
                }
            }
        }
    }
}
</style>
