import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import App from './App.vue';
import router from './router';
import Popper from 'vue3-popper';
import { clickOutside } from './directives';
// import appendCounters from '@/utils/appendCounters.js'; Temporary disabled. Reason: appended counters don't work
import getLocale from '@/utils/getLocale.js';

const app = createApp(App);

// appendCounters(); Temporary disabled. Reason: appended counters don't work
const locale = getLocale();
const i18n = createI18n({ locale });
app.use(i18n);
app.use(router);
app.use(Popper);
app.directive('click-outside', clickOutside);
app.mount('#app');
