<template>
	<div class="landing-navbar">
		<div class="landing-navbar-logo-container">
			<vic-icon />
			<span>Vic’s Science Studio</span>
		</div>
		<navbar-items @showFeedback="$emit('showFeedback')" class="landing-navbar-desktop-items" />
		<button class="landing-navbar-mobile-menu-btn" @click="showMenu = !showMenu">
			<bars-icon v-if="!showMenu" class="mobile-menu-btn-icon" />
			<x-mark-icon v-else class="mobile-menu-btn-icon" />
		</button>

		<div :class="['landing-navbar-mobile-menu', { hidden: !showMenu }]">
			<div class="landing-navbar-mobile-menu-links">
				<navbar-items
					@showFeedback="$emit('showFeedback')"
					@closeMenu="showMenu = false"
					class="landing-navbar-mobile-items"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import VicIcon from '@/components/icons/VicIcon.vue';
import BarsIcon from '@/components/icons/BarsIcon.vue';
import XMarkIcon from '@/components/icons/XMarkIcon.vue';
import NavbarItems from './NavbarItems.vue';

export default {
	data() {
		return {
			showMenu: false,
		};
	},
	components: {
		VicIcon,
		BarsIcon,
		XMarkIcon,
		NavbarItems,
	},
};
</script>

<style lang="scss" scoped>
.landing-navbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.5rem 1.5rem 0.5rem 0.5rem;
	border: 2px solid $primary-dark;
	border-radius: 1.5rem;

	@include media-breakpoint-down(sm) {
		padding: 0.5rem 1rem;
	}

	&-logo-container {
		flex-shrink: 0;
		padding: 0.5rem 0.75rem;
		border: 1px solid $text-primary;
		border-radius: 1.25rem;

		span {
			@extend %jura-label;
			margin-left: 0.5rem;
			color: $text-primary;
		}

		@include media-breakpoint-down(xl) {
			border: unset;
			border-radius: 0;

			span {
				display: none;
			}
		}

		@include media-breakpoint-down(sm) {
			padding: 0;
		}
	}
	&-desktop-items {
		@include media-breakpoint-down(lg) {
			display: none;
		}
	}

	&-mobile-menu-btn {
		@include media-breakpoint-up(lg) {
			display: none;
		}

		.mobile-menu-btn-icon {
			width: 1.5rem;
			height: 1.5rem;
		}
		color: $primary-dark;
		border: none;

		&,
		&:active,
		&:hover,
		&:focus {
			background-color: transparent;
			box-shadow: none;
		}
	}

	&-mobile-menu {
		@include media-breakpoint-up(lg) {
			display: none;
		}

		position: absolute;
		top: 6.25rem;
		left: 1.25rem;
		z-index: 9999;
		width: calc(100% - 2.5rem);
		height: max-content;
		transition: height 0.25s;
		background-color: $text-white;
		border: 2px $primary-dark solid;
		border-radius: 1.25rem;
		overflow-y: hidden;
		padding: 0 1.5rem;

		&.hidden {
			height: 0;
			padding: 0;
			pointer-events: none;
			border: unset;
		}

		@include media-breakpoint-down(sm) {
			top: 5.25rem;
		}
	}
}
</style>
