<template>
    <modal v-model:show="modalShow" :headerLine="false" :footerLine="false" size="small" align="center" class="confirm-modal">
        <template v-slot:body>
            <div class="confirm-modal-text">
                <p>{{ $t('text-1') }}</p>
                <p>{{ $t('text-2') }}</p>
            </div>
        </template>
        <template v-slot:footer>
            <landing-button @click="modalShow=false" outline>{{ $t('close') }}</landing-button>
        </template>
    </modal>
</template>

<i18n>
{
    "ru": {
        "text-1": "Ваша заявка принята.",
        "text-2": "Мы скоро свяжемся с вами.",
        "close": "Закрыть"
    },
    "en": {
        "text-1": "Your request has been accepted.",
        "text-2": "We will contact you soon.",
        "close": "Close"
    }
} 
</i18n>

<script>
import Modal from '@/components/modals/Modal.vue';
import LandingButton from '@/components/LandingButton.vue';

export default {
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        event: {
            type: String,
            default: '',
        },
    },
    computed: {
        modalShow: {
            get() {
                return this.show;
            },
            set(show) {
                this.$emit('update:show', show);
            },
        },
    },
    components: {
        Modal,
        LandingButton,
    },
};
</script>

<style lang="scss" scoped>
.confirm-modal {
    &-text {
        text-align: center;

        p {
            @extend %roboto-body-1;
            margin-bottom: 0;
        }
    }

    .landing-button {
        margin: 0 auto;
    }
}
</style>
