<template>
	<h1 class="chemistry-title">Создана помогать преподавать химию</h1>
	<div class="content">
		<img src="@/assets/images/chemistry_image.png" :alt="alt" class="content-image" />
		<div class="list-text">
			<ul>
				<li>
					Решение создано для изучения российских образовательных программ по химии и может быть использовано
					для подготовки к государственным экзаменам.
				</li>
				<li>
					<a
						class="text-email"
						href="https://vrchemlab.notion.site/8d943e624fe74449adf4e3dbcfd86d02?v=7c513ebe58a84d758e40dd13ebb8c284"
						target="_blank"
						>Список из более чем 100 доступных задач</a
					>
					постоянно расширяется.
				</li>
				<li>Химики и учителя химии принимают активное и непосредственное участие в разработке.</li>
				<li>
					Конструктор задач позволяет в кратчайшие сроки адаптировать продукт под разные образовательные
					задачи.
				</li>
				<li>Задачи создаются на разных языках. Продукт может использоваться для билингвального обучения.</li>
				<li>
					<a
						class="text-email"
						href="https://vrchemlab.notion.site/07dc94efa2d44ae5a0b69786e5cb35d3?v=0482956cde6a4f8ab3ac7deedee17b51"
						>Методические материалы и инструкции размещены в бесплатной базе знаний для учителя.</a
					>
					База постоянно расширяется и пополняется.
				</li>
				<li>
					Система автоматической проверки выполнения задач позволяет отображать в личном кабинете учителя
					статистику работы и результаты учеников.
				</li>
				<li>
					Успешно пройдена
					<a class="text-email" :href="require('@/assets/pdf/oтчет-НТИ-2021.pdf')" target="_blank"
						>всероссийская апробация при поддержке ДВФУ и Минпросвещения.</a
					> Приложение
					<a class="text-email" href="https://reestr.digital.gov.ru/reestr/322719/" target="_blank"
						>зарегистрировано в Реестре российского ПО</a
					>
					и прошло
					<a class="text-email" :href="require('@/assets/pdf/заключение-ФГБНУ.pdf')" target="_blank"
						>экспертизу ФГБНУ «Психологический институт Российской академии образования»</a
					>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	computed: {
		alt() {
			if (this.$i18n.locale === 'en') {
				return '';
			}
			return 'Картинка человека в VR очках';
		},
	},
};
</script>

<style lang="scss" scoped>
.chemistry-title {
	@extend %roboto-headline-3;
	color: $primary-dark;
	text-align: center;
}
.content {
	display: flex;
	gap: 1.5rem;
	margin: 6rem auto 9rem;
	justify-content: center;

	&-image {
		margin: 3.093rem 0 3.093rem;
		object-fit: contain;
	}

	.list-text {
		max-width: 49.25rem;
		color: $text-secondary;
		@extend %roboto-body-1;

		li {
			margin-bottom: 1rem;
		}
	}
}

@include media-breakpoint-down(xxl) {
	.content {
		padding: 0 1.687rem;
	}
}

@include media-breakpoint-down(xl) {
	.chemistry-title {
		margin: 0 auto;
	}
	.content {
		max-width: 47.875rem;
		padding: 0;
		margin: 2rem auto 8rem;

		&-image {
			display: none;
		}
		.list-text {
			max-width: unset;
		}
	}
}

@include media-breakpoint-down(md) {
	.content {
		margin: 3rem auto 3.625rem;

		.list-text li {
			margin-bottom: 0.5rem;
		}
	}
}
</style>
