<template>
	<svg width="90" height="96" viewBox="0 0 90 96" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M20.5213 41.1431C20.1591 41.1431 19.7903 40.9525 19.4254 40.5728L15.4056 36.3877C14.8576 35.8174 14.8576 34.6755 15.4056 34.1039C15.9535 33.5336 17.0493 33.5336 17.5972 34.1039L20.3382 36.9579L26.1875 30.8701C26.7355 30.2999 27.8353 30.2999 28.3832 30.8701C28.9311 31.4418 28.9311 32.5823 28.3832 33.1539L21.6198 40.7634C21.438 40.9525 20.8888 41.1431 20.5213 41.1431Z"
			fill="currentColor"
		/>
		<path
			d="M20.5213 60.3564C20.1591 60.3564 19.7903 60.1659 19.4254 59.7862L15.4056 55.6011C14.8576 55.0294 14.8576 53.8889 15.4056 53.3173C16.1366 52.9376 17.0493 52.9376 17.7803 53.5078L20.5213 56.3605L26.3706 50.274C26.9212 49.7024 28.0171 49.7024 28.5663 50.274C29.1142 50.8443 29.1142 51.9848 28.5663 52.5579L21.6198 59.9767C21.438 60.3564 20.8888 60.3564 20.5213 60.3564Z"
			fill="currentColor"
		/>
		<path
			d="M20.5213 79.7612C20.1591 79.7612 19.7903 79.5707 19.4254 79.1896L15.4056 75.0031C14.8576 74.4342 14.8576 73.2923 15.4056 72.7207C15.9535 72.1504 17.0493 72.1504 17.5972 72.7207L20.5213 75.7653L26.3706 69.6788C26.9212 69.1072 28.0171 69.1072 28.5663 69.6788C29.1142 70.2491 29.1142 71.3896 28.5663 71.9612L21.6198 79.1896C21.438 79.5707 20.8888 79.7612 20.5213 79.7612Z"
			fill="currentColor"
		/>
		<path
			d="M77.5917 14.4025L81.1679 17.1676L85.5712 11.006L81.9951 8.23818L77.5917 14.4025ZM82.094 21.4653L73.6841 15.0326L81.7024 4.19632L90 10.8029L82.094 21.4653Z"
			fill="currentColor"
		/>
		<path
			d="M53.8186 56.6731C49.9003 62.1629 45.3232 67.0073 40.1755 71.1145C42.3885 65.1393 45.5397 59.192 49.5636 53.3893L76.715 15.5229C77.2896 15.3713 77.3123 15.6717 78.0273 15.6717C79.5681 15.6717 80.243 17.8303 81.3682 18.1628L53.8186 56.6731ZM89.9452 10.9191C89.8049 10.8593 88.1076 8.07898 82.4828 18.0473C81.8908 19.096 76.4223 12.6758 75.4881 13.0013L75.0912 13.1376L47.4107 51.7425C42.2789 59.1363 38.5237 66.7597 36.2505 74.4011L35.1279 78.1787L38.2751 75.9658C45.072 71.1882 51.0229 65.2548 55.9635 58.3324L87.1468 14.6675L89.9452 10.9191Z"
			fill="currentColor"
		/>
		<path
			d="M51.0805 51.1693L55.2407 54.3892L57.9014 50.6686L53.7373 47.4474L51.0805 51.1693ZM55.7913 58.2822L47.34 51.7424L53.1867 43.5543L61.638 50.0956L55.7913 58.2822Z"
			fill="currentColor"
		/>
		<path
			d="M59.5869 50.9278V85.2183H10.5321V21.0381H59.5869V37.0873L62.2597 33.3584V18.2563H7.85938V88H62.2597V47.1864L59.5869 50.9278Z"
			fill="currentColor"
		/>
		<path
			d="M34.817 6.9656C34.2744 6.9656 33.9603 7.28975 33.9603 7.85874C33.9603 8.41104 34.4588 8.75049 34.817 8.75049C35.3462 8.75049 35.6736 8.23019 35.6736 7.85874C35.6736 7.30505 35.1751 6.9656 34.817 6.9656Z"
			fill="currentColor"
		/>
		<path d="M21.5306 19.5938H47.9189V13.4267H21.5306V19.5938Z" fill="currentColor" />
		<path
			d="M46.6981 9.24908H39.5123V7.85821C39.5123 5.10568 37.2779 2.78154 34.6345 2.78154C32.0446 2.78154 29.9385 5.05839 29.9385 7.85821V9.24908H22.7527C22.0324 9.24908 21.5312 9.77204 21.5312 10.5217V10.6455H32.5698C31.7974 9.96537 31.2882 8.96534 31.2882 7.85821C31.2882 5.76356 32.805 4.18354 34.8163 4.18354C36.73 4.18354 38.3457 5.86649 38.3457 7.85821C38.3457 8.96534 37.8365 9.96537 37.0641 10.6455H47.9195V10.5217C47.9195 9.77204 47.4184 9.24908 46.6981 9.24908Z"
			fill="currentColor"
		/>
		<path
			d="M34.8169 8.75066C34.4587 8.75066 33.9602 8.41129 33.9602 7.85772C33.9602 7.29025 34.2743 6.96617 34.8169 6.96617C35.175 6.96617 35.6735 7.30555 35.6735 7.85772C35.6735 8.23047 35.3461 8.75066 34.8169 8.75066ZM38.3463 7.85772C38.3463 5.86739 36.7306 4.18444 34.8169 4.18444C32.8043 4.18444 31.2875 5.76446 31.2875 7.85772C31.2875 8.96485 31.7966 9.96489 32.5691 10.645H37.0647C37.8371 9.96489 38.3463 8.96485 38.3463 7.85772Z"
			fill="currentColor"
		/>
		<path
			d="M21.5314 10.5217C21.5314 9.77204 22.0326 9.24908 22.7529 9.24908H29.9386V7.85821C29.9386 5.05839 32.0448 2.78154 34.6347 2.78154C37.2781 2.78154 39.5125 5.10568 39.5125 7.85821V9.24908H46.6983C47.4186 9.24908 47.9197 9.77204 47.9197 10.5217V10.6455H50.5925V10.5217C50.5925 8.24765 48.8832 6.46734 46.6983 6.46734H42.0623C41.4102 2.84274 38.2723 -0.000193596 34.6347 -0.000193596C31.0171 -0.000193596 27.9995 2.79406 27.3835 6.46734H22.7529C20.5679 6.46734 18.8586 8.24765 18.8586 10.5217V10.6455H21.5314V10.5217Z"
			fill="currentColor"
		/>
		<path
			d="M67.4461 39.9216V89.8426C67.4461 91.7286 66.0255 93.2071 64.2121 93.2071L5.81062 93.2099C4.92325 93.2669 4.11073 92.9512 3.52405 92.3142C2.97614 91.7203 2.67277 90.9094 2.67277 90.0318V16.7929C2.67277 14.9055 4.09336 13.427 5.90683 13.427H18.8591V10.6452H5.90683C2.59527 10.6452 0 13.3463 0 16.7929V90.0318C0 91.6313 0.566628 93.1265 1.59565 94.2406C2.64204 95.3755 4.05861 96 5.58343 96C5.72107 96 5.86006 95.9944 5.90683 95.9889H64.2121C67.525 95.9889 70.1189 93.2878 70.1189 89.8426V36.1802L67.4461 39.9216Z"
			fill="currentColor"
		/>
		<path
			d="M64.2122 10.6455H50.5931H47.9203V13.4273V19.5944H21.5307V13.4273V10.6455H18.8579V13.4273V22.3761H50.5931V13.4273H64.2122C65.9348 13.4273 67.4463 15.0004 67.4463 16.7932V26.1245L70.119 22.397V16.7932C70.119 13.4607 67.4142 10.6455 64.2122 10.6455Z"
			fill="currentColor"
		/>
		<path
			d="M37.0642 10.6459H32.5699C33.186 11.1894 33.9665 11.5327 34.8164 11.5327C35.665 11.5327 36.4455 11.1894 37.0642 10.6459Z"
			fill="currentColor"
		/>
	</svg>
</template>

<script>
export default {
	name: 'NotepadIcon',
};
</script>
