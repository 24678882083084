export default function getLocale() {
    const hostname = window.location.hostname;
	let locale;
	if (hostname.endsWith(process.env.VUE_APP_EN_DOMAIN) || hostname.endsWith(process.env.VUE_APP_EN_PROXY_DOMAIN)) {
		locale = 'en';
	}
	if (hostname.endsWith(process.env.VUE_APP_RU_DOMAIN)) {
		locale = 'ru';
	}

	if (!locale) {
		locale = 'ru';
	}
	return locale;
}
