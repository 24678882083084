import { createRouter, createWebHistory } from 'vue-router';
import Landing from '@/views/landing/ru/Landing.vue';
import LandingEn from '@/views/landing/en/Landing.vue';
import Privacy from '@/views/ru/Privacy.vue';
import AboutVr from '@/views/ru/AboutVr.vue';
import getLocale from '@/utils/getLocale.js';
import UserAgreementRu from '@/views/ru/UserAgreementRu'
import UserAgreementEn from '@/views/en/UserAgreementEn'
import SafetyPrecautions from '@/views/ru/SafetyPrecautions.vue';


const routesRu = [
	{
		path: '/',
		name: 'Landing',
		component: Landing,
	},
	{
		path: '/privacy',
		name: 'Privacy',
		component: Privacy,
	},
	{
		path: '/about_vr',
		name: 'AboutVr',
		component: AboutVr,
	},
	{
		path: '/user-agreement',
		name: 'UserAgreement',
		component: UserAgreementRu,
	},
	{
		path: '/safety-precautions',
		name: 'SafetyPrecautions',
		component: SafetyPrecautions,
	},
];

const routesEn = [
	{
		path: '/',
		name: 'LandingEn',
		component: LandingEn,
	},
	{
		path: '/user-agreement',
		name: 'UserAgreement',
		component: UserAgreementEn,
	},
];

const routes = getLocale() === 'ru' ? routesRu: routesEn;

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior(to, s) {
		if (to.name == ('UserAgreement' || 'SafetyPrecautions' || 'Privacy')) return { top: 0 }
		return
	}
});

router.beforeEach(() => {
	document.body.classList.remove('body--overflow-hidden');
});

export default router;
