<template>
	<div id="pirces-block" class="price">
		<h1 class="title">Цены</h1>
		<div class="prices">
			<p class="text-price">Стоимость лицензии на одно устройство составляет:</p>
			<p class="text-price">1 год - 50 000&nbsp;&#8381;</p>
			<p class="text-price">3 года - 100 000&nbsp;&#8381;</p>
			<p class="text-price">5 лет - 150 000&nbsp;&#8381;</p>
		</div>
		<p class="text-explanation">
			Поставка осуществляется в виде лицензионного ключа, позволяющего присоединить к аккаунту организации
			оплаченное количество устройств
		</p>
		<div class="buttons">
			<landing-button @click.stop="$emit('showFeedback', 'buy')" outline>Купить</landing-button>
			<landing-button @click.stop="$emit('showFeedback', 'demo')" outline>Получить демо</landing-button>
		</div>
		<div class="little-text">
			<p>
				Если вы хотите получить специальные условия, свяжитесь с нами:
				<a href="mailto:vr@stemgames.ru" class="text-email">vr@stemgames.ru</a>
			</p>
			<p>Мы можем выслать демоверсию и постараться помочь с демонстрацией, если у вас нет VR-оборудования</p>
		</div>
	</div>
</template>

<script>
import LandingButton from '@/components/LandingButton.vue';

export default {
	components: { LandingButton },
};
</script>

<style lang="scss" scoped>
.price {
	@extend %roboto-headline-4;
	text-align: center;
	color: $text-secondary;
	width: 100%;

	.title {
		@extend %roboto-headline-3;
		color: $primary-dark;
		margin-bottom: 2.312rem;
	}

	.prices {
		.text-price {
			&:first-of-type {
				margin-bottom: 0.5rem;
			}

			&:not(:first-of-type):not(:last-of-type) {
				margin-bottom: 0;
			}

			&:last-of-type {
				margin-bottom: 0.5rem;
			}
		}
	}

	.text-explanation {
		max-width: 76.812rem;
		margin: 0 auto 2.5rem;
	}

	.buttons {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: 2.5rem;

		@include media-breakpoint-down(md) {
			gap: 1rem;
		}
	}

	.little-text {
		margin-top: 2.5rem;
		margin-bottom: 9rem;

		p {
			@extend %roboto-body-1;
			margin: 0;
		}
	}

	@include media-breakpoint-down(xxl) {
		.title {
			margin-bottom: 2rem;
		}
	}

	@include media-breakpoint-down(xl) {
		.text-explanation {
			max-width: 43.812rem;
			margin: 0 auto 1.812rem;
		}
		.little-text {
			margin-bottom: 8rem;
		}
	}

	@include media-breakpoint-down(md) {
		.title {
			margin-bottom: 3.062rem;
		}
		.text-explanation {
			margin: 0 1.5rem 2rem;
		}
		.little-text {
			margin-top: 2.625rem;
			margin-bottom: 4.25rem;
		}
	}
}
</style>
