<template>
	<component
		:is="currentTag"
		:href="href"
		:class="['landing-button', { 'landing-button-outline': outline, 'landing-button-link': link }, buttonSize]"
		type="button"
	>
		<slot />
	</component>
</template>

<script>
const SMALL = 'small';
const MEDIUM = 'medium';
const LARGE = 'large';

export default {
	props: {
		size: {
			// Possible values: 'small', 'medium', 'large' .
			type: String,
			default: LARGE,
		},
		link: {
			type: Boolean,
			default: false,
		},
		outline: {
			type: Boolean,
			default: false,
		},
		href: {
			type: String,
			default: '',
		},
	},
	computed: {
		currentTag() {
			return this.href ? 'a' : 'button';
		},
		buttonSize() {
			let sizeClass = '';
			if (this.link) return sizeClass;

			switch (this.size) {
				case SMALL:
					sizeClass = 'landing-button-small';
					break;
				case MEDIUM:
					sizeClass = 'landing-button-medium';
					break;
				case LARGE:
					sizeClass = 'landing-button-large';
					break;
			}
			return sizeClass;
		},
	},
};
</script>

<style lang="scss" scoped>
.landing-button {
	text-decoration: unset;
	color: $text-white;
	background-color: $primary-dark;
	border: 2px solid $primary-dark;
	transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;

	&:hover {
		background-color: $landing-button-hover;
		border-color: $landing-button-hover;
	}

	&:active {
		background-color: $landing-button-active;
		border-color: $landing-button-active;
	}

	@include media-breakpoint-down(sm) {
		padding: 1rem 0;
		width: 100%;
	}

	&-outline {
		color: $primary-dark;
		background-color: $transparent;

		&:hover {
			color: $text-white;
			background-color: $primary-dark;
		}

		&:active {
			background-color: $landing-button-active;
			border-color: $primary;
		}
	}

	&-small {
		@extend %roboto-link;
		padding: 0.875rem 1rem;
		border-radius: 1.5rem;
	}

	&-medium {
		@extend %roboto-button-medium;
		padding: 1rem 3rem;
		border-radius: 1.5rem;
	}

	&-large {
		@extend %roboto-button-large;
		padding: 1.5rem 5rem;
		border-radius: 2rem;
	}

	&-link {
		@extend %roboto-link;
		color: $primary-dark;
		background-color: unset;
		border: unset;
		border-radius: unset;
		padding: unset;

		&:hover,
		&:active {
			background-color: unset;
			text-decoration: underline;
		}
	}
}
</style>
