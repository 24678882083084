<template>
	<svg width="78" height="116" viewBox="0 0 78 116" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M24.1097 44.5508C22.7277 44.5508 21.7243 45.5066 21.7243 46.8233C21.7243 48.1399 22.7277 49.0985 24.1097 49.0985C25.2642 49.0985 26.5004 48.1831 26.5004 46.8233C26.5004 45.5066 25.4943 44.5508 24.1097 44.5508ZM24.1097 50.9976C21.711 50.9976 19.8982 49.2016 19.8982 46.8233C19.8982 44.4449 21.711 42.6504 24.1097 42.6504C26.5138 42.6504 28.3238 44.4449 28.3238 46.8233C28.3238 49.125 26.4348 50.9976 24.1097 50.9976Z"
			fill="currentColor"
		/>
		<path
			d="M19.2134 37.0826C18.4535 37.0826 17.925 37.5759 17.925 38.2809C17.925 38.9859 18.4535 39.4819 19.2134 39.4819C19.9706 39.4819 20.499 38.9859 20.499 38.2809C20.499 37.5759 19.9706 37.0826 19.2134 37.0826ZM19.2134 41.3823C17.4367 41.3823 16.1016 40.0503 16.1016 38.2809C16.1016 36.5156 17.4367 35.185 19.2134 35.185C20.9873 35.185 22.3251 36.5156 22.3251 38.2809C22.3251 40.0503 20.9873 41.3823 19.2134 41.3823Z"
			fill="currentColor"
		/>
		<path
			d="M22.3538 114.1H21.9779C16.3777 114.1 11.3689 112.018 7.86778 108.237C4.46568 105.068 2.41478 100.92 1.93584 96.363C1.61744 96.4466 1.28298 96.5023 0.912399 96.5023V94.6019C1.24953 94.6019 1.54921 94.5266 1.82881 94.3943C1.82881 94.358 1.82346 94.3218 1.82346 94.2828C1.82346 85.264 8.01092 77.4184 16.8714 75.2087L17.5724 75.0317V60.0525L16.846 59.8922C15.4185 59.5843 14.4231 58.3861 14.4231 56.9761C14.4231 55.3027 15.8974 53.8886 17.6446 53.8886H26.8971C28.8262 53.8886 30.1199 55.4853 30.1199 56.9761C30.1199 58.3861 29.1246 59.5843 27.6944 59.8922L26.9706 60.0525V71.235C27.5325 70.3308 28.1332 69.4558 28.7968 68.6254V61.5377C30.6818 60.8216 31.9434 59.0381 31.9434 56.9761C31.9434 54.2271 29.6811 51.9895 26.8971 51.9895H17.6446C14.862 51.9895 12.597 54.2271 12.597 56.9761C12.597 59.0381 13.8613 60.8216 15.7476 61.5377V73.5479C6.43897 76.1937 0 84.6203 0 94.2828C0 100.201 2.34388 105.646 6.60353 109.603C10.4016 113.712 15.8814 116 21.9779 116H22.3699C28.6724 115.894 34.2819 113.252 38.2352 109.081C37.6532 108.804 37.078 108.515 36.5187 108.198C32.916 111.769 27.9218 114.008 22.3538 114.1Z"
			fill="currentColor"
		/>
		<path
			d="M5.87142 92.2083C7.0059 90.1351 7.79655 88.8756 9.24676 88.8756C10.697 88.8756 11.4863 90.1351 12.6208 92.2083C13.7205 94.2202 14.9673 96.5024 17.5828 96.5024C20.1956 96.5024 21.4397 94.2202 22.5394 92.2083C22.902 91.5437 23.2298 90.9753 23.5562 90.4946C23.3916 89.7018 23.2739 88.8951 23.1816 88.08C22.2491 88.9285 21.5722 90.1351 20.9528 91.2692C19.8196 93.3411 19.0276 94.602 17.5828 94.602C16.1299 94.602 15.3406 93.3411 14.2061 91.2692C13.1064 89.2573 11.8609 86.9779 9.24676 86.9779C6.6313 86.9779 5.38846 89.2573 4.28475 91.2692C3.42051 92.8576 2.74625 93.9625 1.82983 94.3944C1.83385 95.0576 1.86863 95.7152 1.93686 96.3617C3.87002 95.8518 4.92825 93.936 5.87142 92.2083Z"
			fill="currentColor"
		/>
		<path
			d="M0.912109 94.6011V96.502C1.28269 96.502 1.61715 96.4462 1.93689 96.3626C1.86866 95.7146 1.83387 95.0568 1.82986 94.3948C1.55025 94.5258 1.24924 94.6011 0.912109 94.6011Z"
			fill="currentColor"
		/>
		<path
			d="M47.0618 22.0161C45.3079 22.0161 44.033 23.2268 44.033 24.8946C44.033 26.6181 45.5982 27.7759 47.0618 27.7759C48.813 27.7759 50.0853 26.5638 50.0853 24.8946C50.0853 23.2268 48.813 22.0161 47.0618 22.0161ZM47.0618 30.1821C44.1173 30.1821 41.7239 27.8094 41.7239 24.8946C41.7239 21.8823 44.0183 19.6085 47.0618 19.6085C50.1 19.6085 52.3944 21.8823 52.3944 24.8946C52.3944 27.9083 50.1 30.1821 47.0618 30.1821Z"
			fill="currentColor"
		/>
		<path
			d="M48.0625 2.40756C47.1006 2.40756 46.4317 3.02897 46.4317 3.92486C46.4317 4.81795 47.1006 5.44633 48.0625 5.44633C49.0244 5.44633 49.6933 4.81795 49.6933 3.92486C49.6933 3.02897 49.0244 2.40756 48.0625 2.40756ZM48.0625 7.85254C45.8149 7.85254 44.1226 6.16387 44.1226 3.92486C44.1226 1.68723 45.8149 -4.04778e-05 48.0625 -4.04778e-05C50.31 -4.04778e-05 52.0051 1.68723 52.0051 3.92486C52.0051 6.16387 50.31 7.85254 48.0625 7.85254Z"
			fill="currentColor"
		/>
		<path
			d="M53.2651 12.5565C52.3059 12.5565 51.6343 13.1821 51.6343 14.0752C51.6343 14.9669 52.3059 15.5953 53.2651 15.5953C54.227 15.5953 54.8959 14.9669 54.8959 14.0752C54.8959 13.1821 54.227 12.5565 53.2651 12.5565ZM53.2651 18.0015C51.0175 18.0015 49.3225 16.3142 49.3225 14.0752C49.3225 11.8376 51.0175 10.1503 53.2651 10.1503C55.5153 10.1503 57.205 11.8376 57.205 14.0752C57.205 16.3142 55.5153 18.0015 53.2651 18.0015Z"
			fill="currentColor"
		/>
		<path
			d="M43.9322 33.7552C41.4893 33.7552 39.8505 35.7769 39.8505 37.6662C39.8505 39.4524 41.1107 40.9697 42.9208 41.3598L43.8385 41.5632V60.7655L42.9756 60.9982C31.7981 64.0203 24.1551 74.045 24.3905 85.3822C24.6273 98.9654 35.7407 109.788 49.6862 110.021H50.1638C57.2556 110.021 63.5996 107.385 68.0372 102.595C72.9939 97.9775 75.6896 91.7216 75.6896 84.921C75.6896 73.4946 67.8539 63.5591 56.6282 60.76L55.7426 60.537V41.5632L56.663 41.3598C58.4704 40.9697 59.7333 39.4524 59.7333 37.6662C59.7333 35.5456 57.8644 33.7552 55.6516 33.7552H43.9322ZM50.1638 112.429H49.6648C34.4497 112.172 22.341 100.314 22.0801 85.4282C21.8272 73.2731 29.778 62.4946 41.5254 58.9153V43.4442C39.1401 42.5371 37.5387 40.28 37.5387 37.6662C37.5387 34.1858 40.407 31.349 43.9322 31.349H55.6516C59.1768 31.349 62.0424 34.1858 62.0424 37.6662C62.0424 40.28 60.4437 42.5371 58.053 43.4442V58.6561C69.8446 62.007 78 72.6809 78 84.921C78 92.4183 75.0314 99.3137 69.6386 104.327C64.8251 109.529 57.8858 112.429 50.1638 112.429Z"
			fill="currentColor"
		/>
		<path
			d="M43.9322 33.7552C41.4893 33.7552 39.8505 35.7769 39.8505 37.6662C39.8505 39.4524 41.1107 40.9697 42.9208 41.3598L43.8385 41.5632V60.7655L42.9756 60.9982C31.7981 64.0203 24.1551 74.045 24.3905 85.3822C24.6273 98.9654 35.7407 109.788 49.6862 110.021H50.1638C57.2556 110.021 63.5996 107.385 68.0372 102.595C72.9939 97.9775 75.6896 91.7216 75.6896 84.921C75.6896 73.4946 67.8539 63.5591 56.6282 60.76L55.7426 60.537V41.5632L56.663 41.3598C58.4704 40.9697 59.7333 39.4524 59.7333 37.6662C59.7333 35.5456 57.8644 33.7552 55.6516 33.7552H43.9322ZM50.1638 112.429H49.6648C34.4497 112.172 22.341 100.314 22.0801 85.4282C21.8272 73.2731 29.778 62.4946 41.5254 58.9153V43.4442C39.1401 42.5371 37.5387 40.28 37.5387 37.6662C37.5387 34.1858 40.407 31.349 43.9322 31.349H55.6516C59.1768 31.349 62.0424 34.1858 62.0424 37.6662C62.0424 40.28 60.4437 42.5371 58.053 43.4442V58.6561C69.8446 62.007 78 72.6809 78 84.921C78 92.4183 75.0314 99.3137 69.6386 104.327C64.8251 109.529 57.8858 112.429 50.1638 112.429Z"
			fill="currentColor"
		/>
		<path
			d="M76.4445 87.8203C73.1334 87.8203 71.5561 84.9306 70.1634 82.3823C68.7279 79.7546 67.7219 78.1606 65.8851 78.1606C64.0509 78.1606 63.0502 79.7546 61.616 82.3823C60.2207 84.9306 58.642 87.8203 55.3323 87.8203C52.0211 87.8203 50.4452 84.9306 49.0498 82.3823C47.6157 79.7546 46.615 78.1606 44.7808 78.1606C42.9466 78.1606 41.9433 79.7546 40.5091 82.3823C39.1191 84.9306 37.5378 87.8203 34.2293 87.8203C30.9169 87.8203 29.3436 84.9306 27.9482 82.3823C26.5141 79.7546 25.5107 78.1606 23.6792 78.1606V75.7558C26.9863 75.7558 28.5676 78.6427 29.9576 81.1924C31.3918 83.816 32.3978 85.4127 34.2293 85.4127C36.0608 85.4127 37.0669 83.816 38.5024 81.1924C39.895 78.6427 41.4697 75.7558 44.7808 75.7558C48.0892 75.7558 49.6692 78.6427 51.0592 81.1924C52.4947 83.816 53.4981 85.4127 55.3323 85.4127C57.1691 85.4127 58.1698 83.816 59.6039 81.1924C60.9993 78.6427 62.5779 75.7558 65.8851 75.7558C69.1989 75.7558 70.7775 78.6427 72.1729 81.1924C73.6043 83.816 74.6104 85.4127 76.4445 85.4127V87.8203Z"
			fill="currentColor"
		/>
	</svg>
</template>

<script>
export default {
	name: 'FlasksIcon',
};
</script>
