<template>
	<div class="about-vr page">
		<div class="about-vr-container">
			<h2>Программа для ЭВМ «ВиАр химическая лаборатория»</h2>
			<p>Деятельность программы «ВиАр химическая лаборатория» направлена на достижение следующих целей:</p>
			<ul>
				<li>
					проведение в виртуальной реальности лабораторных работ по химии. Обучение неорганической и
					физической химии по программам среднего, высшего и дополнительного образования;
				</li>
				<li>
					достоверное математическое моделирование и достоверная визуализация количественных и качественных
					химических реакций неорганической химии;
				</li>
				<li>
					симуляция виртуального окружения химической лаборатории, позволяющего пользователю в свободном
					режиме взаимодействовать с объектами лаборатории и выбирать оптимальные пути решения поставленных
					задач с использованием широкого ассортимента химических реактивов и лабораторного оборудования;
				</li>
				<li>
					моделирование лабораторных экспериментов, проведение которых в обычной лаборатории затруднено в силу
					различных причин: эксперименты с применением дорогостоящего оборудования и реактивов, несущие
					опасность для здоровья человека эксперименты, недоступные для проведения в обычной химической
					лаборатории эксперименты.
				</li>
			</ul>
		</div>
		<div class="about-vr-container">
			<h2>Минимальные системные требования</h2>
			<ul>
				<li>процессор Intel Core i5-8300</li>
				<li>ОЗУ 6 Гб DDR3 SDRAM</li>
				<li>видеокарта Nvidia GeForce 1060</li>
				<li>объем жесткого диска 256 Гб</li>
				<li>порт usb 3.0</li>
				<li>порт HDMI</li>
				<li>
					модель шлема виртуальной реальности из перечня: HTC Vive, Vive Pro, Vive Pro Eye, Vive Cosmos,
					Oculus Rift, HP Reverb, Dell visor, Samsung HMD Odyssey, Acer AH101
				</li>
				<li>ОС Windows 10</li>
			</ul>
		</div>

		<div class="about-vr-container">
			<h2>Стоимость</h2>
			<span
				>Стоимость лицензии (на одно рабочее место): от 50 000 рублей (за 1 год) до 150 000 рублей (за 5 лет)</span
			>
		</div>
		<div class="about-vr-container">
			<h2>Ссылки</h2>
			<span
				>описание программы:
				<a :href="require('@/assets/pdf/description.pdf')" target="_blank">cкачать pdf</a></span
			><br />

			<span
				>описание процессов, обеспечивающих поддержание жизненного цикла:
				<a :href="require('@/assets/pdf/processes.pdf')" target="_blank">cкачать pdf</a></span
			><br />
			<span>
				инструкция по установке и эксплуатации:
				<a :href="require('@/assets/pdf/instruction.pdf')" target="_blank">cкачать pdf</a></span
			><br />
			<span
				>для получения дистрибутива программы просьба воспользоваться
				<a href="/#pirces-block">формой для обратной связи</a></span
			>
			(раздел "Цены" кнопка "Купить")
		</div>
	</div>
</template>

<style lang="scss">
.about-vr {
	&-container {
		padding: 0.75rem;

		&:first-of-type {
			margin-top: 2rem;
		}
	}
}
</style>
