export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виртуальная реальность дает эффект присутствия, а специальная физико-химическая модель - свободу действий и право на ошибку. Включайте в занятия те эксперименты, которые необходимы, и используйте их для знакомства с новой темой, как проблемные задания или для проверки знаний. Управляйте занятием и получайте статистику выполнения в личном кабинете. Подготовиться к занятиям и узнать о лаборатории больше Вам поможет база знаний."])}
      },
      "en": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vic’s Science Studio enables experiments as in the real chemistry lab despite:"])}
      }
    }
  })
}
