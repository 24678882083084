<template>
	<div class="landing-navbar-items">
		<div class="landing-navbar-items-links">
			<a :href="accountHref">Log in</a>
			<a href="#pirces-block">Prices</a>
			<a href="#contacts-block">Contacts</a>
		</div>
		<div class="landing-navbar-items-links">
			<landing-button @click.stop="handleClick" link class="bold-link">Get started</landing-button>
			<a class="bold-link" :href="require('@/assets/pdf/brochure.pdf')" target="_blank">Get PDF Brochure</a>
			<a class="landing-navbar-items-email" href="mailto:info@vics.studio" @click="$emit('closeMenu')">
				<span class="landing-navbar-items-email-content"> <envelope-icon />info@vics.studio</span>
			</a>
		</div>
	</div>
</template>

<script>
import EnvelopeIcon from '@/components/icons/EnvelopeIcon.vue';
import LandingButton from '@/components/LandingButton.vue';
import { navbarMixin } from '@/mixins';

export default {
	mixins: [navbarMixin],
	methods: {
		handleClick() {
			this.$emit('showFeedback');
			this.$emit('closeMenu');
		},
	},
	components: {
		EnvelopeIcon,
		LandingButton,
	},
};
</script>

<style lang="scss" scoped>
.landing-navbar-items {
	display: flex;
	align-items: center;
	width: 100%;

	@include media-breakpoint-down(lg) {
		flex-direction: column;
		align-items: flex-start;
	}

	&-links {
		&:first-of-type {
			margin-left: auto;
			margin-right: max(calc(100% - 12.9rem - 31rem - 0.5rem - 1.5rem - 36.25rem), 4.5rem);

			@include media-breakpoint-down(lg) {
				margin-left: 0;
				margin-right: 0;
			}
		}
		
		& > * {
			@extend %roboto-link;
			color: $primary-dark;
			text-decoration: unset;

			&:hover {
				text-decoration: underline;
			}
		}

		& > *:not(:first-child) {
			margin-left: 4.25rem;
		}

		@include media-breakpoint-down(lg) {
			display: flex;
			width: 100%;
			flex-direction: column;
			margin: 0;

			& > * {
				text-align: start;
				padding: 1.5rem 0;
				border-bottom: 1px $primary-dark solid;
			}

			&:last-of-type {
				& > *:last-child {
					border-bottom: unset;
				}
			}

			& > *:not(:first-child) {
				margin-left: 0;
			}
		}

		.bold-link {
			@extend %roboto-link-bold;
		}
	}

	&-email {
		text-decoration: unset;

		&:hover {
			text-decoration: underline;
		}

		&-content {
			color: $primary-dark;
			@extend %roboto-link;

			& > svg {
				margin-right: 0.5rem;
			}

			@include media-breakpoint-down(md) {
				padding: 1.5rem 0;

				& > span {
					display: initial;
				}
			}
		}
	}
}
</style>
