<template>
	<svg
		width="29.598177mm"
		height="20.685163mm"
		viewBox="0 0 29.598177 20.685163"
		version="1.1"
		id="svg1"
		xml:space="preserve"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:svg="http://www.w3.org/2000/svg"
	>
		<defs id="defs1" />
		<g id="layer1" transform="translate(-69.483759,-94.112813)">
			<g id="g1" transform="matrix(0.18945146,0,0,0.18945146,69.249642,94.031995)">
				<path
					d="M 154,17.5 C 152.18,10.77 146.93,5.5 140.2,3.7 131.16,0.21 43.6,-1.5 18.2,3.8 11.47,5.62 6.2,10.87 4.4,17.6 0.32,35.5 0.01,74.2 4.5,92.5 c 1.82,6.73 7.07,12 13.8,13.8 17.9,4.12 103,4.7 122,0 6.73,-1.82 12,-7.07 13.8,-13.8 4.35,-19.5 4.66,-55.8 -0.1,-75 z"
					fill="#ff0000"
					id="path1"
				/>
				<path d="M 105,55 64.2,31.6 v 46.8 z" fill="#ffffff" id="path2" />
			</g>
		</g>
	</svg>
</template>

<script>
export default {
	name: 'YoutubeIcon',
};
</script>
