<template>
	<div class="page-content about-page lead">
		<div class="about-page__header">
			<img :src="logoPath" />
			<h3>{{  title  }}</h3>
		</div>
		<div v-for="(paragraph, idx) in paragraps" :key="`paragraph-${idx}`">
			<h4>{{ paragraph.name }}</h4>
			<template v-for="(part, idx) in paragraph.parts" :key="`part-${idx}`">
				<templte v-if="Array.isArray(part)">
					<ul>
						<li v-for="(point, idx) in part" :key="`point-${idx}`">{{ point }}</li>
					</ul>
				</templte>
				<template v-else>
					<span>{{ part }}</span>
				</template>
			</template>
		</div>
	</div>
</template>

<script>
import SAFETYPRECATIONS from '@/json/safety-precautions.json';

export default {
	computed: {
		paragraps() {
			return SAFETYPRECATIONS.paragraps;
		},
		title() {
			return  SAFETYPRECATIONS.title;
		},
		logoPath() {
            return require(`@/assets/images/vrchemlab-logo.png`);
		},
	},
};
</script>

<style lang="scss" scoped>
.about-page {
	padding-left: 4rem;

	&__header {
		display: flex;
		align-items: flex-end;
		gap: 0.5rem;
		padding-left: 2rem;
		margin-bottom: 1rem;

		img {
			width: 3rem;
			height: auto;
		}

		h3 {
			font-weight: bold;
			margin-bottom: 0;
		}
	}
}
</style>
